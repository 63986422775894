import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, ViewContainerRef } from '@angular/core';
import { ConfirmationOverlayRef } from './confirmation-dialog-ref';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  title: string;
  message: string;
  btnOkText: string = 'Delete';
  btnCancelText: string = 'Cancel';

  constructor(
    private ref: ConfirmationOverlayRef
  ) {
    this.title = ref.content['title'];
    this.message = ref.content['message'];
    this.btnOkText = ref.content['btnOkText'];
    this.btnCancelText = ref.content['btnCancelText'];
  }

  public decline() {
    this.ref.close(false);
  }

  public accept() {
    this.ref.close(true);
  }

  public dismiss() {
    this.ref.close(null);
  }

}

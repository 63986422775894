<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-3">
      <label class="font-xs mb-2">Type of Font <small class="text-danger">*</small></label>
      <ng-select placeholder="Select" formControlName="insert_font_type" (change)="onFontTypeChange()"
        [items]="decorationOptions['insert_font_type']" bindLabel="data.name" bindValue="id" labelForId="data.name">
      </ng-select>
      <app-validation-errors [submitted]="invalidForm" [control]="form.get('insert_font_type')"></app-validation-errors>
    </div>
    <div class="col-md-3" *ngIf="isFontTypeOther">
      <label class="font-xs mb-2">Font Name <small class="text-danger">*</small></label>
      <input type="text" class="form-control" formControlName="insert_font_type_other" />
      <app-validation-errors [submitted]="invalidForm"
        [control]="form.get('insert_font_type_other')"></app-validation-errors>
    </div>
    <div class="col-md-3">
      <label class="font-xs mb-2">Text Color Type <small class="text-danger">*</small></label>
      <select class="form-select" formControlName="color_type" (change)="onColorTypeChange()">
        <option value="">Select</option>
        <ng-container *ngFor="let item of decorationOptions['color_type']">
          <option [value]="item.id">{{item.data.swatch_text}}</option>
        </ng-container>
      </select>
      <app-validation-errors [submitted]="invalidForm" [control]="form.get('color_type')"></app-validation-errors>
    </div>
    <div class="col-md-3">
      <label class="font-xs mb-2">Text Color <small class="text-danger">*</small></label>
      <ng-select [items]="colorOptions" bindValue="id" [groupBy]="groupByFn" bindLabel="data.color_name"
        formControlName="color">
        <ng-template ng-label-tmp let-item="item">
          <ng-container *ngIf="item && item.data">
            <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
            </div>
            {{item.data.color_name}}
          </ng-container>
          <ng-container *ngIf="!item.label && !item.data">
            Select
          </ng-container>
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item">
          {{item['data.color_name']}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
          </div>
          {{item.data.color_name}}
        </ng-template>
      </ng-select>
      <app-validation-errors [submitted]="invalidForm" [control]="form.get('color')"></app-validation-errors>
    </div>
    <div class="col-md-3">
      <label class="font-xs mb-2">Placement <small class="text-danger">*</small></label>
      <select class="form-control" formControlName="insert_placement">
        <option value="">Select</option>
        <ng-container *ngFor="let item of decorationOptions['insert_placement']">
          <option [value]="item.id">{{item.data.value}}</option>
        </ng-container>
      </select>
      <app-validation-errors [submitted]="invalidForm" [control]="form.get('insert_placement')"></app-validation-errors>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 font-weight-bold">
      Text
      <hr class="my-2">
    </div>
  </div>
  <ng-container *ngFor="let ctrl of lineFormArray['controls']; let k = index">
    <div class="row">
      <div class="col-md-4 my-2">
        <div class="d-flex gap-3 align-items-center">
          <div style="max-width: 155px;">
            <label class="font-xs mb-2">Line &nbsp;{{k+1}} <small class="text-danger">*</small></label>
            <input type="text" class="form-control" [formControl]="ctrl">
          </div>
          <div class="col-md-3 my-2" *ngIf="k > 0">
            <label class="d-block">&nbsp;</label>
            <a class="cursor-pointer d-block mt-1" (click)="removeLineText(k)">
              <i class="bi bi-x-lg"></i></a>
          </div>
        </div>
        <app-validation-errors fieldName="Text" [submitted]="invalidForm" [control]="ctrl">
        </app-validation-errors>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-md-12 mt-2">
      <a class="text-link cursor-pointer" (click)="addLineText()">+ Add Another Line Text</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-2 text-end">
      <!-- <button type="button" class="btn btn-light" (click)="onClickCancel()">Cancel</button> -->
      <button type="submit" class="btn btn-primary px-5">Save</button>
    </div>
  </div>
</form>
import { Component, HostListener, OnInit, TemplateRef, Type } from '@angular/core';

import { ModalPopupOverlayRef } from './modal-popup-ref';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent implements OnInit {

  title: string;
  content: string | TemplateRef<any> | Type<any>;
  keyboard: boolean;
  size: string;
  headerClass: string;
  bodyClass: string;
  contentType: string;
  isShowCloseButton: boolean;

  @HostListener('document:keyup.esc', ['$event']) handleKeyUp(event) {
    if (this.keyboard) {
      this.dismiss();
    }
  }

  constructor(
    private ref: ModalPopupOverlayRef
  ) { }

  ngOnInit() {
    this.content = this.ref.content;
    this.title = this.ref.data.title;
    this.keyboard = this.ref.data.disableBackdropClick ? false : true;
    this.size = this.ref.data.size ? this.ref.data.size : 'md';
    this.headerClass = this.ref.data.headerClass ? this.ref.data.headerClass : '';
    this.bodyClass = this.ref.data.bodyClass ? this.ref.data.bodyClass : '';
    this.isShowCloseButton = this.ref.data.hideCloseButton ? false : true;
    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
    } else {
      this.contentType = 'component';
    }
    if (this.content) {
      document.querySelector('body').classList.toggle('modal-popup-open');
    }
  }

  dismiss() {
    this.ref.close(null);
  }

}

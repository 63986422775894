import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AccountService } from '@core/account/account.service';

@Component({
  selector: 'app-order-shared-artwork-listing',
  templateUrl: './listing.component.html'
})
export class SharedArtworkListingComponent implements OnChanges {
  @Input() artworkDetails;
  @Input() extension;
  @Input() decorationMethod;
  @Input() editMode: boolean = true;
  @Input() hideLineInfo: boolean;

  isThreadSupported: boolean;

  constructor(private accountService: AccountService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.decorationMethod && this.decorationMethod) {
      this.isThreadSupported = this.accountService.threadSupportedDecorationMethdods.includes(this.decorationMethod);
    }
  }

  hasEmblemData(): boolean {
    return !!(
      this.artworkDetails.size ||
      this.artworkDetails.custom_die_width ||
      this.artworkDetails.custom_die_height ||
      this.artworkDetails.emblem_shape ||
      this.artworkDetails.emblem_shape_other ||
      this.artworkDetails.border_type ||
      this.artworkDetails.border_color_type ||
      this.artworkDetails.border_color ||
      this.artworkDetails.garment_type ||
      this.artworkDetails.garment_content ||
      this.artworkDetails.garment_color ||
      this.artworkDetails.font_type ||
      this.artworkDetails.color_type ||
      this.artworkDetails.emblem_line1 ||
      this.artworkDetails.emblem_line2 ||
      this.artworkDetails.emblem_line3 ||
      this.artworkDetails.fabric_type ||
      this.artworkDetails.fabric_color ||
      this.artworkDetails.finish ||
      this.artworkDetails.laundry_type ||
      this.artworkDetails.backing_type ||
      this.artworkDetails.attachements
    );
  }

}

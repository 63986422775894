import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

// services
import { StorageService } from '@salesonepro/services';
import { AuthService } from '@salesonepro/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard {

  constructor(
    private authService: AuthService,
    private storage: StorageService
  ) { }

  canActivate(): Observable<boolean> {
    const selectedCustomer = this.storage.retrieve('selected_customer');
    if (!selectedCustomer) {
      return observableOf(true);
    }
    return this.authService.isAuthenticated.pipe(take(1), map(bool => !bool));
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class SharedService {

  constructor(private apiService: ApiService, private storage: StorageService) { }

  public isScriptLoadingSubject = new BehaviorSubject<boolean>(false);
  public isScriptLoading = this.isScriptLoadingSubject.asObservable();
  public pendingScriptInitListSubject = new BehaviorSubject<Array<any>>([]);
  public pendingScriptInitList = this.pendingScriptInitListSubject.asObservable();

  getMonths() {
    return [
      { value: '01', name: 'January' },
      { value: '02', name: 'February' },
      { value: '03', name: 'March' },
      { value: '04', name: 'April' },
      { value: '05', name: 'May' },
      { value: '06', name: 'June' },
      { value: '07', name: 'July' },
      { value: '08', name: 'August' },
      { value: '09', name: 'September' },
      { value: '10', name: 'October' },
      { value: '11', name: 'November' },
      { value: '12', name: 'December' }
    ];
  }

  getYears() {
    const date = (new Date()).getFullYear();
    const years = [];
    Array.from(Array(21), (x, i) => {
      years.push(i + date);
    });
    return years;
  }

  getCountryStateList() {
    const countryStateList = this.storage.retrieve('country-state-list');
    if (countryStateList) {
      return of(countryStateList);
    }
    return this.apiService.get('/country_state_list').pipe(
      tap(res => {
        this.storage.store('country-state-list', res);
      }),
      map(data => {
        return data;
      })
    );
  }

  /** Get zipcode details*/
  getZipcodeDetails(params: HttpParams): Observable<any> {
    return this.apiService.get('/zipcode_details', params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  validateAddress({ state, state_text, zip, country }) {
    const body = {
      state,
      state_text,
      zip,
      country
    };
    const url = '/validate_address';
    return this.apiService
      .post(url, body).pipe(
        map(response => {
          return response;
        })
      );
  }
}





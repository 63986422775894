import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-shared-insert-list-details',
  templateUrl: './insert-list-detials.component.html'
})

export class InsertListDetailsComponent {
  @Input() item: any;
  @Input() itemClass: any;
  @Output() insertClick = new EventEmitter();
  @Output() removeClick = new EventEmitter();
  @Input() editMode: boolean = true;


  onIsInsertClick(checked: boolean) {
    this.insertClick.emit(checked);
  }

  removeInsertData() {
    this.removeClick.emit(true);
  }
}
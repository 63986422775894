import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-decoration-type-listing',
  templateUrl: './listing.component.html',
})
export class DecorationTypeListingComponent {
  @Input() data;
  @Input() extension;
  @Input() decorationMethod;
  @Input() showAdditionalFields: boolean;
  @Input() editMode: boolean = true;
  @Input() hideLineInfo: boolean;
}


import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToasterService } from './toaster.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private environment;
  constructor(
    private router: Router,
    private toastr: ToasterService,
    private httpClient: HttpClient,
    private storage: StorageService,
    @Inject('environment')
    environment
  ) {
    this.environment = environment;
  }

  private formatErrors(error: any) {
    let errorMsg = error.error;
    if (error.status === 0 || error.status === 500) {
      errorMsg = error.statusText;
    }

    return observableThrowError(errorMsg);
  }

  handleError(e: any) {
    const detail = (e && e.detail) ? e.detail : '';
    if (detail && detail === 'Signature has expired.') {
      this.router.navigate(['./login']);
    } else {
      if (e === 'Internal Server Error') {
        this.toastr.error(e);
      }
      return e;
    }
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(`${this.environment.apiUrl}${path}`, { params: params }).pipe(
      catchError(this.formatErrors));
  }

  put(path: string, body: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(
      `${this.environment.apiUrl}${path}`,
      JSON.stringify(body), { headers: headers }
    ).pipe(
      catchError(this.formatErrors));
  }

  patch(path: string, body: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.patch(
      `${this.environment.apiUrl}${path}`,
      JSON.stringify(body), { headers: headers }
    ).pipe(
      catchError(this.formatErrors));
  }

  post(path: string, body: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(
      `${this.environment.apiUrl}${path}`,
      JSON.stringify(body), { headers: headers }
    ).pipe(
      catchError(this.formatErrors));
  }

  delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.delete(`${this.environment.apiUrl}${path}`, { params: params }).pipe(
      catchError(this.formatErrors));
  }

  deleteWithData(path: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: body
    };
    return this.httpClient.delete(`${this.environment.apiUrl}${path}`, httpOptions).pipe(
      catchError(this.formatErrors));
  }

  authPost(path: string, creds: any): Observable<any> {
    const token = btoa(this.environment.clientId + ':' + this.environment.clientSecret);
    let headers = new HttpHeaders().set('Authorization', 'Basic ' + token);
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient.post(
      `${this.environment.authUrl}${path}`, creds, { headers: headers }
    ).pipe(
      catchError(this.formatErrors));
  }

  putFile(path: string, body: any): Observable<any> {
    return this.httpClient.put(
      `${this.environment.apiUrl}${path}`,
      body
    ).pipe(
      catchError(this.formatErrors));
  }

  patchFile(path: string, body: any): Observable<any> {
    return this.httpClient.patch(
      `${this.environment.apiUrl}${path}`,
      body
    ).pipe(
      catchError(this.formatErrors));
  }

  patchFileWithResponse(path: string, body: any): Observable<any> {
    return this.httpClient.patch(
      `${this.environment.apiUrl}${path}`,
      body, {
        observe: 'response',
        responseType: 'blob',
      }
    ).pipe(
      catchError(this.formatErrors));
  }

  postFile(path: string, body: any): Observable<any> {
    return this.httpClient.post(
      `${this.environment.apiUrl}${path}`,
      body
    ).pipe(
      catchError(this.formatErrors));
  }

  getFile(path: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.httpClient
      .get(`${this.environment.apiUrl}${path}`, {
        headers: headers,
        responseType: 'blob'
      });
  }

  getFileWithParams(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    return this.httpClient
      .get(`${this.environment.apiUrl}${path}`, {
        responseType: 'blob',
        params: params
      });
  }

  getFileWithParamsResponse(path: string, params: HttpParams = new HttpParams()) {
    return this.httpClient
      .get(`${this.environment.apiUrl}${path}`, {
        responseType: 'blob',
        params: params,
        observe: 'response'
      }, );
  }

  getPdfFileWithParams(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.httpClient
      .get(`${this.environment.apiUrl}${path}`, {
        headers: headers,
        responseType: 'blob',
        params: params
      });
  }

  getExternal(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(`${path}`, { params: params }).pipe(
      catchError(this.formatErrors));
  }

  postPdfFile(path: string, body: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient
      .post(
        `${this.environment.apiUrl}${path}`,
        JSON.stringify(body), {
        headers: headers,
        responseType: 'blob',
      }
      ).pipe(catchError(this.formatErrors));
  }

  getAccessToken() {
    if (this.storage.retrieve('authToken')) {
      return this.storage.retrieve('authToken');
    } else {
      return null;
    }
  }

  postWithAuthToken(path: string, body: any): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.getAccessToken());
    headers = headers.set('Content-Type', 'application/json');
    return this.httpClient.post(
      `${this.environment.apiUrl}${path}`,
      JSON.stringify(body), { headers: headers}
    ).pipe(
    catchError(this.formatErrors));
  }
}

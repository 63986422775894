<div class="card">
  <div class="card-body" [ngClass]="{'loader-height': partialLoading || isAddressLoading}" [appSpinner]="partialLoading || isAddressLoading">
    <div class="row" *ngIf="addressList.count > limit && !showAddressForm">
      <div class="col-md-6">
        <label>Search</label>
        <input class="form-control" type="text" [formControl]="searchFormControl">
      </div>
      <div class="col-md-6" *ngIf="addressList.count > limit">
        <lib-salesonepro-icon class="cursor-pointer" name="chevron-right" (click)="onPaginate('next')">
        </lib-salesonepro-icon>
        <lib-salesonepro-icon class="cursor-pointer" name="chevron-left" (click)="onPaginate('prev')">
        </lib-salesonepro-icon>
      </div>
    </div>
    <app-shared-address-form *ngIf="showAddressForm && !isAddressLoading"></app-shared-address-form>
    <ng-container *ngIf="!showAddressForm && !isAddressLoading && !partialLoading">
      <div class="address-list-block">
        <ng-container *ngIf="addressList.count">
          <div class="address-list-row" *ngFor="let address of addressList['results']; let i=index"
            [ngClass]="{'active': selectedAddressId === address.id}">
            <div class="col-md-6" class="select-address-wrap select-address-set cursor-pointer">
              <div class="form-check payment-details-wrap">
                <input type="radio" [value]="address.id" [checked]="address.id === selectedAddressId" name="radio"
                  id="address{{i}}" class="form-check-input" (change)="emitRadioChange($event)">
                <label class="form-check-label cursor-pointer card-number-wrap" for="address{{i}}">
                    {{address.name}}, {{address.address_1}} {{address.address_2}} {{address.city}},
                    {{address.state}}
                    {{address.zip}}
                </label>
              </div>
              <div class="col-md-6" *ngIf="parentType === 'normal'">
                <button *ngIf="(selectedAddressType !== 'shipping_address')" (click)="editBillingAddress(address)"
                  class="edit-add-change ml-2 btn btn-primary">
                  Edit
                </button>
                <button [disabled]="isAddressUpdating" class="ml-1 btn btn-primary" (click)="onSelectAddress(address)"
                  *ngIf="(selectedAddressId === address.id)">
                  <lib-salesonepro-icon name="spin fa-spinner" *ngIf="isAddressUpdating"></lib-salesonepro-icon>
                  Use this {{selectedAddressType === 'shipping_address' ? 'Shipping' : 'Billing'}} Address
                </button>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="parentType === 'chase' && !selectedAddressId && invalidFormData">
            <div class="col-md-12">
              <div class="ml-3 mt-1">
                <span class="font-sm text-danger">Please select an address</span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12 p-3 ml-3">
          <a class="cursor-pointer text-primary font-weight-bold" (click)="addNewAddress()">+ Add New Address</a>
        </div>
      </div>
    </ng-container>
  </div>
</div>
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService } from '@salesonepro/services';


@Injectable({
  providedIn: 'root'
})
export class AddressAutocompleteService {
  constructor(
    private apiService: ApiService,
  ) { }

  getAddressList(params: HttpParams) {
    return this.apiService.get('/account/addresses', params);
  }
}
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, ControlContainer, Validators, FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { isEmpty } from '@salesonepro/utils';
import { AccountService } from '@core/account/account.service';

@Component({
  selector: 'app-order-shared-artwork-form',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
  templateUrl: './form.component.html'
})
export class SharedArtworkFormComponent implements OnChanges, OnDestroy {
  @Input() logoDetails;
  @Input() logoThreadDetails;
  @Input() dstInfo;
  @Input() artworkSharedData;
  @Input() error;
  @Input() invalidForm: boolean;
  @Input() showAdditionalFields = true;
  @Input() mediaExt: string;
  @Input() decorationMethod = 'flat_embroidery';
  @Input() decorationSchema: any;
  @Input() decorationOptions: any;
  @Input() isDataReadOnly: boolean;
  @Output() colorChange = new EventEmitter();
  private unsubscribe$ = new Subject<void>();


  isReadOnly: boolean;
  isDigitized: boolean;
  containsFormElement: boolean;

  form: FormGroup;
  noOfColors = '';
  totalStitchCount = null;
  haveThreadDetails: boolean;
  customerInfo: any;
  decorationSchemaKeys = [];
  decorationFormGroup: FormGroup;

  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
    private accountService: AccountService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error && this.error) {
      if (this.error['data'] && this.error['data'][this.decorationMethod]) {
        this.error = this.error['data'][this.decorationMethod];
      }
    }
    if (changes.decorationMethod && this.decorationMethod && this.form) {
      this.form = null;
    }
    if (!this.form) {
      this.form = this.parent.form;
      this.decorationSchemaKeys = this.decorationSchema ? Object.keys(this.decorationSchema['properties']) : [];
      let dataFormGp = this.form.get('data') as FormGroup;
      if (!dataFormGp) {
        this.parent.form.addControl('data', this.fb.group({}));
        dataFormGp = this.parent.form.get('data') as FormGroup;
      }
      let decorationFormGroup = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;

      if (!decorationFormGroup) {
        dataFormGp.addControl(this.decorationMethod, this.fb.group({}));
        decorationFormGroup = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
      }
      this.decorationFormGroup = decorationFormGroup;
      if (this.decorationSchema['properties']['logo_width']) {
        this.containsFormElement = true;
        decorationFormGroup.addControl('logo_width', new FormControl(null, [Validators.required, Validators.min(0.01)]));
      }
      if (this.decorationSchema['properties']['logo_size']) {
        this.containsFormElement = true;
        decorationFormGroup.addControl('logo_size', new FormControl(null, [Validators.required, Validators.min(0.01)]));
      }

      if (this.decorationSchema['properties']['total_stitch_count']) {
        decorationFormGroup.addControl('total_stitch_count', new FormControl(null));
      }
      if (this.decorationSchema['properties']['is_tape_edit']) {
        decorationFormGroup.addControl('is_tape_edit', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_digitizing_required']) {
        decorationFormGroup.addControl('is_digitizing_required', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_special_location']) {
        decorationFormGroup.addControl('is_special_location', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_neon_thread']) {
        decorationFormGroup.addControl('is_neon_thread', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_match_tone_charge']) {
        decorationFormGroup.addControl('is_match_tone_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_reorder_setup_charge']) {
        decorationFormGroup.addControl('is_reorder_setup_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_screen_setup']) {
        decorationFormGroup.addControl('is_screen_setup', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_film_charge']) {
        decorationFormGroup.addControl('is_film_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_artwork_charge']) {
        decorationFormGroup.addControl('is_artwork_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_special_ink']) {
        decorationFormGroup.addControl('is_special_ink', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_flash_charge']) {
        decorationFormGroup.addControl('is_flash_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_nylon_item_charge']) {
        decorationFormGroup.addControl('is_nylon_item_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_pms_matching_charge']) {
        decorationFormGroup.addControl('is_pms_matching_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_speciality_item_charge']) {
        decorationFormGroup.addControl('is_speciality_item_charge', new FormControl(false));
      }
      if (this.decorationSchema['properties']['is_artwork_required']) {
        decorationFormGroup.addControl('is_artwork_required', new FormControl(false));
      }

      // if (this.decorationSchema['properties']['thread_details']) {
      //   decorationFormGroup.addControl('thread_details', new FormArray([]));
      // }

      if (this.decorationSchema['properties']['no_of_colors'] && this.decorationSchema['properties']['thread_details']) {
        decorationFormGroup.addControl('no_of_colors', new FormControl('', [Validators.required, Validators.min(1)]));
        setTimeout(() => {
          decorationFormGroup.get(['no_of_colors']).valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged())
            .subscribe((value) => {
              this.colorChange.emit(value);
            });
        }, 100);
      }
    }

    this.setAccountDetails();
    if (changes.artworkSharedData && this.artworkSharedData) {
      if (this.artworkSharedData.logo_width && this.decorationSchema['properties']['logo_width']) {
        this.form.get(['data', this.decorationMethod, 'logo_width']).setValue(parseFloat(this.artworkSharedData.logo_width));
      }
      if (this.artworkSharedData.logo_size && this.decorationSchema['properties']['logo_size']) {
        this.form.get(['data', this.decorationMethod, 'logo_size']).setValue(parseFloat(this.artworkSharedData.logo_size));
      }

      if (this.decorationMethod !== 'digital_printing'
        && (this.decorationSchema['properties']['logo_width'] || this.decorationSchema['properties']['logo_size'])
        && (this.artworkSharedData.logo_width || this.artworkSharedData.logo_size)
      ) {
        this.onDimensionChange(parseFloat(this.artworkSharedData.logo_width), 'logo_width');
        this.onDimensionChange(parseFloat(this.artworkSharedData.logo_size), 'logo_size');
      }
      this.renderDstData();
    }
    if (changes.logoDetails && !isEmpty(this.logoDetails)) {
      if (this.logoDetails && this.logoDetails['data'][this.decorationMethod] && this.form.get(['data', this.decorationMethod])) {
        if (this.logoDetails.additional_data && this.logoDetails.additional_data.original_data) {
          this.isDigitized = true;
        }
        const artworkData = this.logoDetails['data'][this.decorationMethod];
        const controls = this.form.get(['data', this.decorationMethod]) as FormGroup;
        if (this.decorationSchema['properties']['logo_width']) {
          controls.get(['logo_width']).setValue(artworkData['logo_width'] ? artworkData['logo_width'] : null);
          this.onDimensionChange(artworkData['logo_width'], 'logo_width');
        }
        if (this.decorationSchema['properties']['logo_size']) {
          controls.get(['logo_size']).setValue(artworkData['logo_size'] ? artworkData['logo_size'] : null);
          this.onDimensionChange(artworkData['logo_size'], 'logo_size');
        }
        if (this.decorationSchema['properties']['total_stitch_count']) {
          controls.get(['total_stitch_count']).setValue(artworkData['total_stitch_count'] ? artworkData['total_stitch_count'] : null);
        }
        if (this.decorationSchema['properties']['is_tape_edit']) {
          controls.get(['is_tape_edit']).setValue(artworkData['is_tape_edit'] ? artworkData['is_tape_edit'] : false);
        }
        if (this.decorationSchema['properties']['is_digitizing_required']) {
          controls.get(['is_digitizing_required']).setValue(artworkData['is_digitizing_required'] ? artworkData['is_digitizing_required'] : false);
        }
        if (this.decorationSchema['properties']['no_of_colors'] && this.decorationSchema['properties']['thread_details']) {
          controls.get(['no_of_colors']).setValue(artworkData['no_of_colors'] ? artworkData['no_of_colors'] : null);
        }
      }
    }
    if (changes.mediaExt && this.mediaExt) {
      if (this.mediaExt === 'dst') {
        this.isReadOnly = true;
      } else {
        this.isReadOnly = false;
        if (isEmpty(this.logoDetails) && this.form && this.form.get(['data', this.decorationMethod, 'no_of_colors'])) {
          this.form.get(['data', this.decorationMethod, 'no_of_colors']).setValue(null);
        }
      }
      if (changes.isDataReadOnly) {
        this.isReadOnly = this.isDataReadOnly;
      }
    }
  }

  setInsertFormData(data) {
    const lineData = data.lines.map((value) => this.fb.control(value));
    return this.fb.group({
      'insert_font_type': [data.insert_font_type.id, Validators.required],
      'insert_placement': [data.insert_placement.id, Validators.required],
      'insert_font_type_other': [data.insert_font_type_other],
      'lines': this.fb.array(lineData),
      'color_type': [data.color_type.id, Validators.required],
      'color': [data.color.id, Validators.required],
      'qty': [1, Validators.required],
    });
  }

  setAccountDetails() {
    this.accountService.getAccountDetails().subscribe(response => {
      this.customerInfo = response;
      const decorationForm = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
      decorationForm.addControl('customer_type', new FormControl(this.customerInfo.details.group));
    });
  }

  get data() { return this.form.get(['data']) as FormGroup; }

  renderDstData() {
    const controls = this.form.get(['data', this.decorationMethod]) as FormGroup;
    if (this.decorationSchema['properties']['no_of_colors'] && controls.get(['no_of_colors'])) {
      const noOfColors = this.artworkSharedData.no_of_colors;
      if (noOfColors !== undefined && noOfColors !== null && noOfColors > 0) {
        controls.get(['no_of_colors']).setValue(parseInt(this.artworkSharedData.no_of_colors, 10));
      } else {
        controls.get(['no_of_colors']).setValue('');
      }
    }
    if (this.decorationSchema['properties']['total_stitch_count'] && controls.get(['total_stitch_count'])) {
      controls.get(['total_stitch_count']).setValue(this.artworkSharedData.total_stitch_count ? this.artworkSharedData.total_stitch_count : null);
    }
  }

  onDimensionChange(value, type) {
    if (type === 'logo_width') {
      const control = this.form.get(['data', this.decorationMethod, 'logo_size']);
      if (value !== '' && value > 0) {
        control.clearValidators();
      } else {
        control.setValidators([Validators.required, Validators.min(0.01)]);
      }
      control.updateValueAndValidity();
    }

    if (type === 'logo_size') {
      const logoWidthControl = this.form.get(['data', this.decorationMethod, 'logo_width']);
      if (value !== '' && value > 0) {
        logoWidthControl.clearValidators();
      } else {
        logoWidthControl.setValidators([Validators.required, Validators.min(0.01)]);
      }
      logoWidthControl.updateValueAndValidity();
    }
  }

  checkProportional(type) {
    if (this.form.get(['data', this.decorationMethod, 'logo_size']) && this.form.get(['data', this.decorationMethod, 'logo_width'])) {
      const logo_size = this.form.get(['data', this.decorationMethod, 'logo_size']).value;
      const logo_width = this.form.get(['data', this.decorationMethod, 'logo_width']).value;
      if (type === 'logo_size') {
        if (logo_width > 0 && (logo_size === '' || logo_size === null)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (logo_size > 0 && (logo_width === '' || logo_width === null)) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

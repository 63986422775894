<form [formGroup]="addressForm" [appSpinner]="isLoading">
  <div class="custom-form">
    <div class="row">
      <div class="col-lg-6">
        <label for="fname" class="font-xs mb-2">Name*</label>
        <div class="input-group rounded-2 form-custom mb-2">
          <span class="input-group-text"><i class="bi bi-person-circle"></i></span>
            <input type="text" formControlName="name" class="form-control py-2" name="fname" value=""
              placeholder="First Name*" autofocus>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('name')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="name"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
       <label for="cname" class="font-xs mb-2">Email</label>
      <div class="input-group rounded-2 form-custom mb-2">
          <span class="input-group-text"><i class="bi bi-envelope-fill"></i></span>
            <input type="email" formControlName="email" class="form-control py-2" name="email" value="" placeholder="Email">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('email')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="email"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <label for="cname" class="font-xs mb-2">Company Name</label>
        <div class="input-group rounded-2 form-custom mb-2">
          <span class="input-group-text"><i class="bi bi-bank"></i></span>
            <input type="text" formControlName="company_name" class="form-control py-2" name="cname" value=""
              placeholder="Company Name">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('company_name')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="company_name"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
          <label for="staddress1" class="font-xs mb-2">Street Address*</label>
        <div class="input-group form-custom mb-2 rounded-2">
          <span class="input-group-text"><i class="bi bi-house-fill"></i></span>
            <input type="text" class="form-control py-2" name="staddress1" GooglePlaces
              (addressSelect)="fillInAddress($event)" formControlName="address_1" placeholder="Street Address*">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('address_1')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="address_1"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <label for="staddress2" class="font-xs mb-2">Street Address 2 </label>
        <div class="input-group form-custom mb-2 rounded-2">
          <span class="input-group-text"><i class="bi bi-house-fill"></i></span>
            <input type="text" class="form-control py-2" name="staddress2" formControlName="address_2"
              placeholder="Street Address 2">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('address_2')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="address_2"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <label for="city" class="font-xs mb-2">City* </label>
        <div class="input-group rounded-2 form-custom mb-2">
          <span class="input-group-text"><i class="bi bi-buildings-fill"></i></span>
            <input type="text" formControlName="city" class="form-control py-2" name="city" value="" placeholder="City*">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('city')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="city"></app-server-validation-error>
        </div>
      </div>

      <!-- <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-geo-alt-fill"></i></span>
          <div class="form-floating">
            <select id="state" (change)="onChangeCountry($event.target.value)" formControlName="country"
              class="form-select border-0">
              <option value="">Select</option>
              <option *ngFor="let item of countryList" [value]="item.code">{{item.name}}
              </option>
            </select>
            <label for="state">Country* </label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('country')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="country"></app-server-validation-error>
        </div>
      </div> -->

      <div class="col-lg-6" *ngIf="addressForm.controls['country'].value === 'US'">
            <label for="state"  class="font-xs mb-2">State* </label>
        <div class="input-group rounded-2 form-custom mb-2">
          <span class="input-group-text"><i class="bi bi-geo-alt-fill"></i></span>
            <select formControlName="state" class="form-select border-0 py-2" aria-label="Choose State">
              <option value="">Select</option>
              <option *ngFor="let item of stateList" [value]="item.code">{{item.name}}
              </option>
            </select>   
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('state')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="state"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6" *ngIf="addressForm.controls['country'].value !== 'US'">
          <label for="state" class="font-xs mb-2">State* </label>
        <div class="input-group form-custom mb-2 rounded-2">
          <span class="input-group-text"><i class="bi bi-geo-alt-fill"></i></span>
            <input type="text" formControlName="state_text" class="form-control py-2" name="state" value=""
              placeholder="State*">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('state_text')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="state_text"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <label for="zipcode" class="font-xs mb-2">Zip Code* </label>
        <div class="input-group form-custom rounded-2 mb-2">
          <span class="input-group-text"><i class="bi bi-envelope-fill"></i></span>
            <input type="text" formControlName="zip" class="form-control py-2" name="zipcode" value=""
              placeholder="Zip Code*">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('zip')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="zip"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
            <label for="phonenumber" class="font-xs mb-2">Phone Number*</label>
        <div class="input-group rounded-2 form-custom mb-2">
          <span class="input-group-text"><i class="bi bi-telephone-fill"></i></span>
            <input type="text" formControlName="phone" class="form-control py-2" name="phonenumber" [textMask]="{mask: mask}"
              placeholder="Phone Number*">
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('phone')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="phone"></app-server-validation-error>
        </div>
      </div>

    </div>
  </div>
</form>
<ng-select #ngSelectElement class="custom-ng-select" [formControl]="inputFormControl" [items]="listData"
  bindValue="id" [typeahead]="searchKey" [searchFn]="customSearchFn" [loading]="loadingListData"
  [placeholder]="placeholder" (clear)="onFilterClear()" (scroll)="onScroll($event)" (scrollToEnd)="onScrollToEnd()"
  (search)="onFilterSearch($event)" (change)="onSelect($event)" (open)="onOpenSelect()">
  <ng-template ng-label-tmp let-item="item">
    <ng-container *ngIf="item && item.id !== 'new'">
      {{item.name}}, {{item.address_1}}{{item.address_2 ? ', ' + item.address_2 : ''}},
      {{item.city}}, {{item.state}} {{item.zip}}, {{item.country}}
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <strong [ngClass]="{'text-primary': item.id === 'new'}">{{item.name}}</strong>
    <ng-container *ngIf="item.id !== 'new'">
      <p class="mb-0" *ngIf="item.attention_to">, {{item.attention_to}}, </p>
      <br> {{item.address_1}}, {{item.address_2}}
      <br> {{item.city}}, {{item.state}} {{item.zip}}
      <br> {{item.country}}
    </ng-container>
  </ng-template>
</ng-select>
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, ControlContainer, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-order-shared-line-only-form',
  templateUrl: './line-only-form.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
})
export class SharedLineOnlyFormComponent implements OnChanges {
  @Input() decorationMethod: string;
  @Input() logoDetails;
  @Input() error;
  @Input() invalidForm: boolean;
  @Input() loadingDecorationOptions: boolean;
  @Input() decorationOptions: any;
  @Input() decorationSchema: any;
  @Input() noOfLines: number = 3;

  form: FormGroup;
  data: any;
  decorationSchemaKeys = [];
  customerInfo: any;

  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.decorationMethod && this.decorationMethod && this.form) {
      this.form = null;
    }
    if (changes.decorationOptions && this.decorationOptions) {
      const data = this.logoDetails && this.logoDetails['data'][this.decorationMethod] ? this.logoDetails['data'][this.decorationMethod] : null;
      this.data = data;
      if (!this.form) {
        this.form = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
        if (!this.form) {
          let dataFormGp = this.parent.form.get('data') as FormGroup;
          if (!dataFormGp) {
            this.parent.form.addControl('data', this.fb.group({}));
            dataFormGp = this.parent.form.get('data') as FormGroup;
          }
          dataFormGp.addControl(this.decorationMethod, this.fb.group({}));
          this.form = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
        }

        this.decorationSchemaKeys = this.decorationSchema ? Object.keys(this.decorationSchema['properties']) : [];

        const dataKeys = Object.keys(data);
        dataKeys.forEach(key => {
          if (this.decorationSchema['properties'][key]) {
            if (key === 'emblem_line1') {
              this.form.addControl('emblem_line1', new FormControl(data?.emblem_line1 && data.emblem_line1 !== 'one_program' || '', [Validators.required]));
            } else {
              this.form.addControl(key, new FormControl(data[key] && data[key]['id'] ? data[key]['id'] : data[key]));
            }
          }
        });
      }
    }
  }


}

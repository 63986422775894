import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService, StorageService } from '@salesonepro/services';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  threadSupportedDecorationMethdods = [
    'flat_embroidery',
    'three_dimension_embroidery',
    'text_embroidery',
    'other',
    'handcut',
    'embroidered_specials',
    'es_letter',
    'pennbroidery',
    'emb_penn_edge_eg',
    'emb_penn_edge_pg',
    'penn_stitch',
    'etched_leather'
  ];
  sharedDecorationList = [
    'ij_blank',
    'dye_sublimated',
    'es_blank',
    'handcut',
    'embroidered_specials',
    'es_letter',
    'ij_letter',
    'pennbroidery',
    'emb_penn_edge_eg',
    'emb_penn_edge_pg',
    'silk_penn_edge_rh',
    'silk_penn_edge_rt',
    'penn_flex_badge',
    'penn_flex_dome',
    'penn_flex_logo',
    'penn_stitch',
    'penn_trans',
    'penn_weave',
    'silk_screen_emblem',
    'ts_letter',
    'sublimated_label',
    'woven_label',
    'ink_jet',
    'es_pg_letter',
    'ig_emblem',
    'silk_screen_printed_emblem',
    'ts_printed_letter',
    'ts_blank',
    'silk_penn_edge_rh_letter',
    'silk_penn_edge_rt_letter',
    'penn_trans_plh',
    'penn_trans_pli',
    'etched_leather',
    'es_eg_letter'
  ];

  isInsertDecorationList = [
    'embroidered_specials',
    'emb_penn_edge_pg',
    'emb_penn_edge_eg',
    'penn_stitch',
  ];

  private showBreadcrumbSubject = new BehaviorSubject<boolean>(true);
  showBreadcrumb$ = this.showBreadcrumbSubject.asObservable();
  public cartSummarySubject = new BehaviorSubject<any>(null);
  public getCartSummary$ = this.cartSummarySubject.asObservable();
  public isCartLoadingSubject = new BehaviorSubject<boolean>(false);
  public isCartLoading = this.isCartLoadingSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private storage: StorageService
  ) { }

  setShowBreadcrumbState(state: boolean): void {
    this.showBreadcrumbSubject.next(state);
  }

  getShowBreadcrumbState(): boolean {
    return this.showBreadcrumbSubject.value;
  }

  // Method is used for getting all the saved address as per the params
  getCustomerAddress(params: HttpParams) {
    return this.apiService.get('/account/addresses', params).pipe(
      map(data => {
        return data;
      })
    );
  }
  // Method is used for creating new address
  public createAddress(formData): Observable<any> {
    return this.apiService
      .post('/account/addresses', formData).pipe(
        map(response => {
          return response;
        })
      );
  }

  // Method is used for getting address data as per the ID.
  public getAddressById(guid): Observable<any> {
    const url = '/account/addresses/' + `${guid}`;
    return this.apiService.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  // Method is used for updating the saved address.
  public updateAddress(formData, guid) {
    const url = '/account/addresses/' + `${guid}`;
    return this.apiService
      .patch(url, formData).pipe(
        map(response => {
          return response;
        })
      );
  }

  // Method is used for validating the address.
  validateAddress({ state, state_text, zip, country }) {
    const body = {
      state,
      state_text,
      zip,
      country
    };
    return this.apiService
      .post('/validate_address', body).pipe(
        map(response => {
          return response;
        })
      );
  }

  // Method is used for deleting the saved address
  deleteCustomerAddress(id: any) {
    const url = `/account/addresses/${id}`;
    return this.apiService.delete(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  // Method is used for adding new card on MY_SAVED_CARDS
  getSavedCards() {
    return this.apiService.get('/account/payment_profiles').pipe(
      map(response => {
        return response;
      })
    );
  }

  // Method is used for adding new card on MY_SAVED_CARDS => ADD_NEW_CARDS
  addNewCard(formData): Observable<any> {
    return this.apiService
      .post('/account/payment_profiles', formData).pipe(
        map(response => {
          return response;
        })
      );
  }

  // Method used to delete card
  deleteCard(id: string) {
    const url = `/account/payment_profiles/${id}`;
    return this.apiService.delete(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  // Method is used for getting the photocard data
  getPhotoCards(params: HttpParams): Observable<any> {
    return this.apiService.get('/photocard_search', params).pipe(
      map(response => {
        return response;
      })
    );
  }


  // Method is used for getting the photocard data by ID
  getPhotoCardByID(id: any): Observable<any> {
    const url = `/photocard_gallery/${id}`;
    return this.apiService.get(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  // Method is used for request catelog
  createRequestCatelog(frmbody: any): Observable<any> {
    return this.apiService.post('/photocard_gallery_export', frmbody).pipe(
      map(response => {
        return response;
      })
    );
  }

  // Method is used for getting the Request catelog data
  postRequestCatelogData(params: HttpParams): Observable<any> {
    return this.apiService.get('/photocard_gallery_export', params).pipe(
      map(response => {
        return response;
      })
    );
  }

  // Method is used for download Request catelog data
  getRequestCatelogPDF(id: any,): Observable<Blob> {
    const url = `/photocard_gallery_export/${id}/get_pdf`;
    return this.apiService.getFile(url).pipe(
      map(response => {
        return response;
      })
    );
  }


  getDecorationOptions(id: string, params: HttpParams): Observable<any> {
    const decorationOption = this.storage.retrieve('decoration_option_' + id, 'storage');
    if (decorationOption) {
      return of(decorationOption);
    }
    const url = `/decoration_methods/${id}/options`;
    return this.apiService.get(url, params).pipe(
      tap(response => {
        // this.storage.store('decoration_option_' + id, response, 'storage');
      }),
      map(
        data => data
      ));
  }

  getDecorationSchema(id: string, params: HttpParams): Observable<any> {
    const decorationOption = this.storage.retrieve('decoration_schema_' + id, 'storage');
    if (decorationOption) {
      return of(decorationOption);
    }
    const url = `/decoration_methods/${id}/schema`;
    return this.apiService.get(url, params).pipe(
      tap(response => {
        // this.storage.store('decoration_schema_' + id, response, 'storage');
      }),
      map(
        data => data
      ));
  }

  deletePhotoCardByID(id: any): Observable<any> {
    const url = `/photocard_gallery/${id}`;
    return this.apiService.delete(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  updatePhotocardDetails(artwork) {
    const url = `/photocard_gallery/${artwork.id}`;
    const formData = this.formatFormData(artwork);
    return this.apiService
      .patchFile(url, formData).pipe(
        map(data => {
          return data;
        }));

  }

  // Method is used for getting logged-in User information
  getUserInfo(): Observable<any> {
    const userInfo = this.storage.retrieve('user');
    if (userInfo) {
      return of(userInfo);
    }
    return this.apiService.get('/auth-user').pipe(
      map(response => {
        return response;
      })
    );
  }

  getAccountDetails() {
    const accountDetails = this.storage.retrieve('account-details');
    if (accountDetails) {
      return of(accountDetails);
    }
    return this.apiService.get('/account/my_profile').pipe(
      tap(res => {
        this.storage.store('account-details', res);
      }),
      map(data => {
        return data;
      })
    );

  }

  // Method used to update the logged-in user Information.
  updateUserInfo(userInfo): Observable<any> {
    return this.apiService
      .patch('/account/profile', userInfo).pipe(
        map(response => {
          return response;
        })
      );
  }

  // Method used to update password.
  changeUserPassword(credentials): Observable<any> {
    return this.apiService
      .post('/account/resetpassword', credentials).pipe(
        map(response => {
          return response;
        })
      );
  }

  getDstInfo(file) {
    const data = {
      'file': file
    };
    return this.apiService.post('/dst_reader', data).pipe(
      map(response => {
        return response;
      }));
  }

  getDstInfoFromUrl(url) {
    const data = {
      'url': url
    };
    return this.apiService
      .post('/dst_reader_from_url', data).pipe(
        map(response => {
          return response;
        }));
  }

  getSpreadsheetType() {
    const url = '/account/spreadsheet_types';
    return this.apiService.get(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  downloadSpreadsheetTemplate(code: string) {
    const params = new HttpParams().append('code', code);
    const url = '/account/spreadsheet_type_download';
    return this.apiService.getFileWithParams(url, params).pipe(
      map(response => {
        return response;
      })
    );
  }

  formatFormData(data: any) {
    const formData = new FormData();
    for (const key in data) {
      if (key) {
        if (data[key] && typeof data[key] === 'object' && data[key].constructor === Object) {
          if (key === 'data') {
            formData.append('data', JSON.stringify(data.data));
          } else {
            for (const k in data[key]) {
              if (k) {
                formData.append(key + '.' + k, data[key][k]);
              }
            }
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    return formData;
  }

  uploadSpreadsheet(cartId: string, code: string, data: any) {
    const params = new HttpParams().append('spreadsheet_type', code).toString();
    const url = `/cart/${cartId}/spreadsheet_upload?${params}`;
    const formData = this.formatFormData(data);
    return this.apiService.patchFileWithResponse(url, formData).pipe(
      map(response => {
        return response;
      })
    );
  }

  generateCartId() {
    return this.apiService.get('/cart').pipe(
      map(response => {
        this.storage.store('cart_id', response.id, 'session');
        return response;
      })
    );
  }

  formatAddress(address) {
    return `${address?.address_1}, ${address?.city}, ${address?.state} ${address?.zip}, ${address?.country}`;
  }

  loadCartSummary() {
    if (!this.isCartLoadingSubject.value) {
      const storedCartId = this.storage.retrieve('cart-id');
      if (this.storage.retrieve('default_customer')) {
        if (storedCartId) {
          const url = `/cart/${storedCartId}/summary`;
          this.isCartLoadingSubject.next(true);
          this.apiService.get(url).subscribe(summary => {
            this.isCartLoadingSubject.next(false);
            this.storage.store('cart-item-count', summary.total_qty, 'session');
            this.cartSummarySubject.next(summary);
          });
        } else {
          this.isCartLoadingSubject.next(true);
          this.apiService.get('/cart').subscribe(response => {
            const cartId = response.id;
            this.storage.store('cart-id', cartId);
            const url = `/cart/${cartId}/summary`;
            this.apiService.get(url).subscribe(summary => {
              this.isCartLoadingSubject.next(false);
              this.storage.store('cart-item-count', summary.total_qty, 'session');
              this.cartSummarySubject.next(summary);
            });
          });
        }
      }
    }
  }

  getCartSummary() {
    const storedCartId = this.storage.retrieve('cart-id');
    if (storedCartId) {
      const url = `/cart/${storedCartId}/summary`;
      this.isCartLoadingSubject.next(true);
      return this.apiService.get(url).pipe(
        map(
          data => {
            this.isCartLoadingSubject.next(false);
            this.cartSummarySubject.next(data);
            return data;
          }
        ));
    } else {
      return of(false);
    }
  }

  getCustomerPrograms() {
    return this.apiService.get('/account/customer_programs').pipe(
      map(response => {
        return response;
      })
    );
  }
}
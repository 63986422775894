/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, ControlContainer, Validators, FormControl } from '@angular/forms';

import { isEmpty } from '@salesonepro/utils';
import { AccountService } from '@core/account/account.service';

@Component({
  selector: 'app-decoration-type-form',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
  template: 'parent template',
})
export class DecorationTypeFormComponent implements OnChanges {
  @Input() logoDetails;
  @Input() logoThreadDetails;
  @Input() dstInfo;
  @Input() artworkSharedData;
  @Input() error;
  @Input() invalidForm: boolean;
  @Input() showAdditionalFields = true;
  @Input() mediaExt: string;
  @Input() decorationType = 'flat_embroidery';
  @Input() loadingDecorationOptions: boolean;
  @Input() decorationOptions: any;

  @Output() colorChange = new EventEmitter();
  isReadOnly: boolean;
  isDigitized: boolean;

  form: FormGroup;
  noOfColors = '';
  totalStitchCount = null;

  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
    private accountService: AccountService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error && this.error) {
      if (this.error['data'] && this.error['data'][this.decorationType]) {
        this.error = this.error['data'][this.decorationType];
      }
    }

    if (changes.decorationType && this.decorationType && this.form) {
      this.form = null;
    }

    if (!this.form) {
      this.form = this.parent.form;
      this.addDataFormGroup();
    } else if (!this.form.get('data')) {
      this.addDataFormGroup();
    }

    if (changes['decorationType'] && changes['decorationType']['currentValue'] !== changes['decorationType']['previousValue']) {
      this.resetForm();
    }


    if (this.mediaExt === 'dst') {
      this.isReadOnly = true;
    } else {
      this.isReadOnly = false;
    }

    this.setAccountDetails();

    if (changes.artworkSharedData && this.artworkSharedData) {
      if (this.artworkSharedData.logo_width) {
        this.form.get(['data', this.decorationType, 'logo_width']).setValue(parseFloat(this.artworkSharedData.logo_width));
      }
      if (this.artworkSharedData.logo_size) {
        this.form.get(['data', this.decorationType, 'logo_size']).setValue(parseFloat(this.artworkSharedData.logo_size));
      }

      if (this.decorationType !== 'digital_printing' && (this.artworkSharedData.logo_width || this.artworkSharedData.logo_size)) {
        this.onDimensionChange(parseFloat(this.artworkSharedData.logo_width), 'logo_width');
        this.onDimensionChange(parseFloat(this.artworkSharedData.logo_size), 'logo_size');
      }
      this.renderDstData();
    }

    if (changes.logoDetails && !isEmpty(this.logoDetails)) {

      if (this.logoDetails && this.logoDetails['data'][this.decorationType]) {
        if (this.logoDetails.additional_data && this.logoDetails.additional_data.original_data) {
          this.isDigitized = true;
        }
        const embroidery = this.logoDetails['data'][this.decorationType];
        const controls = this.form.get(['data', this.decorationType]) as FormGroup;
        if (isEmpty(this.dstInfo)) {
          controls.get(['logo_width']).setValue(embroidery['logo_width'] ? embroidery['logo_width'] : null);
          controls.get(['logo_size']).setValue(embroidery['logo_size'] ? embroidery['logo_size'] : null);
          if (this.decorationType !== 'digital_printing') {
            this.onDimensionChange(embroidery['logo_width'], 'logo_width');
            this.onDimensionChange(embroidery['logo_size'], 'logo_size');
          }
        }
        this.loadLogoDetails(embroidery);
      } else {
        // this.resetForm();
      }
    }
  }

  addDataFormGroup() {
    this.form.addControl('data', this.fb.group({}));
    const dataFormGp = this.form.get('data') as FormGroup;
    dataFormGp.addControl(this.decorationType, this.fb.group({
      logo_width: [null, [Validators.required, Validators.min(0.01)]],
      logo_size: [null, [Validators.required, Validators.min(0.01)]]
    }));
    this.initForm();
  }

  get data() { return this.form.get(['data']) as FormGroup; }

  initForm() {}
  resetForm() {
    this.isReadOnly = false;
    const formGrp = this.form.get(['data', this.decorationType]) as FormGroup;
    formGrp.reset();
    formGrp.get(['logo_width']).setValue(null);
    formGrp.get(['logo_size']).setValue(null);
    this.loadLogoDetails({});
  }
  renderDstData() {}
  loadLogoDetails(data) {}

  onInputChange(value) {
    this.colorChange.emit(value);
  }

  setAccountDetails() {
    this.accountService.getAccountDetails().subscribe(response => {
      const decorationForm = this.parent.form.get(['data', this.decorationType]) as FormGroup;
      decorationForm.addControl('customer_type', new FormControl(response.details.group));
    });
  }

  onDimensionChange(value, type) {
    if (type === 'logo_width') {
      const control = this.form.get(['data', this.decorationType, 'logo_size']);
      if (value !== '' && value > 0) {
        control.setValidators(null);
      } else {
        control.setValidators([Validators.required, Validators.min(0.01)]);
      }
      control.updateValueAndValidity();
    }

    if (type === 'logo_size') {
      const logoWidthControl = this.form.get(['data', this.decorationType, 'logo_width']);
      if (value !== '' && value > 0) {
        logoWidthControl.setValidators(null);
      } else {
        logoWidthControl.setValidators([Validators.required, Validators.min(0.01)]);
      }
      logoWidthControl.updateValueAndValidity();
    }
  }

  checkProportional(type) {
    const logo_size = this.form.get(['data', this.decorationType, 'logo_size']).value;
    const logo_width = this.form.get(['data', this.decorationType, 'logo_width']).value;
    if (type === 'logo_size') {
      if (logo_width > 0 && (logo_size === '' || logo_size === null)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (logo_size > 0 && (logo_width === '' || logo_width === null)) {
        return true;
      } else {
        return false;
      }
    }
  }

}

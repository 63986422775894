<div class="list-inner-wrap">
  <div class="">
    <ng-container *ngIf="isThreadSupported">
      <ul class="logo-nav row">
        <li class="col">
          <div class="h6 mb-1 font-weight-bold">Logo Size (Width)</div>
          <div class="font-xs">
            {{artworkDetails.logo_width ? artworkDetails.logo_width : 'Proportional to height'}}
          </div>
        </li>
        <li class="col">
          <div class="h6 mb-1 font-weight-bold">Logo Size (Height)</div>
          <div class="font-xs">
            {{artworkDetails.logo_size ? artworkDetails.logo_size : 'Proportional to width' }}
          </div>
        </li>
        <li class="col" *ngIf="artworkDetails.no_of_colors">
          <div class="h6 mb-1 font-weight-bold"># of Colors</div>
          <div class="font-xs" *ngIf="artworkDetails.no_of_colors">{{artworkDetails.no_of_colors}}</div>
        </li>
        <li class="col" *ngIf="artworkDetails.no_of_flash">
          <div class="h6 mb-1 font-weight-bold"># of Flash</div>
          <div class="font-xs" *ngIf="artworkDetails.no_of_flash">{{artworkDetails.no_of_flash}}</div>
        </li>
        <li class="col-sm-3" *ngIf="artworkDetails.total_stitch_count">
          <div class="h6 mb-1 font-weight-bold">Total Stitch Count</div>
          <div class="font-xs" *ngIf="artworkDetails.total_stitch_count">{{artworkDetails.total_stitch_count}}
          </div>
        </li>
      </ul>
      <ng-container *ngIf="artworkDetails && artworkDetails.thread_details">
        <ng-container *ngTemplateOutlet="colorDetails; context: {key: 'thread_details'}">
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div>
    <div class="row" *ngIf="hasEmblemData()">
      <div class="col-md-12 mt-2">
        <div class="font-weight-bold pt-2">Emblem Specifications</div>
        <hr class="my-2">
      </div>
    </div>
    <div class="col-md-12">
      <ul class="logo-nav row mt-3">
        <li class="col-md-3" *ngIf="artworkDetails.size">
          <div class="h6 mb-1 font-weight-bold">Size</div>
          <div class="font-xs">{{artworkDetails.size.data.value}}</div>
        </li>
        <li class="col-md-3" *ngIf="artworkDetails.custom_die_width">
          <div class="h6 mb-1 font-weight-bold">Custom Die Width</div>
          <div class="font-xs">{{artworkDetails.custom_die_width}}</div>
        </li>
        <li class="col-md-3" *ngIf="artworkDetails.custom_die_height">
          <div class="h6 mb-1 font-weight-bold">Custom Die Height</div>
          <div class="font-xs">{{artworkDetails.custom_die_height}}</div>
        </li>
        <li class="col-md-3"
          *ngIf="artworkDetails.emblem_shape && artworkDetails.emblem_shape.data?.swatch_text !== 'OTHER'">
          <div class="h6 mb-1 font-weight-bold">Emblem Shape</div>
          <div class="font-xs">
            {{artworkDetails.emblem_shape?.data?.swatch_text }}
          </div>
        </li>
        <li class="col-md-3" *ngIf="artworkDetails.emblem_shape_other">
          <div class="h6 mb-1 font-weight-bold">Emblem Shape</div>
          <div class="font-xs">
            {{artworkDetails.emblem_shape_other }}
          </div>
        </li>
      </ul>
      <ul class="logo-nav row mt-3" *ngIf="artworkDetails.border_type">
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Border Type</div>
          <div class="font-xs">
            {{artworkDetails.border_type.data.swatch_text }}
          </div>
        </li>
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Border Color Type</div>
          <div class="font-xs">{{artworkDetails?.border_color_type?.data?.swatch_text}}</div>
        </li>
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Border Color</div>
          <div class="font-xs" *ngIf="artworkDetails?.border_color?.data?.color_name !== 'OTHER'">
            <ng-container *ngTemplateOutlet="colorBlock; context: {item: artworkDetails?.border_color}"></ng-container>
          </div>
          <div class="font-xs" *ngIf="artworkDetails?.border_color?.data?.color_name === 'OTHER'">
            {{artworkDetails?.border_color_other}}</div>
        </li>
      </ul>

      <ul class="logo-nav row mt-3" *ngIf="artworkDetails.garment_type">
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Garment Type</div>
          <div class="font-xs">
            {{artworkDetails?.garment_type?.data?.swatch_text }}
          </div>
        </li>
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Garment Content</div>
          <div class="font-xs" *ngIf="artworkDetails?.garment_content?.data?.swatch_text !== 'OTHER'">
            {{artworkDetails?.garment_content?.data?.swatch_text}}
          </div>
          <div class="font-xs" *ngIf="artworkDetails?.garment_content?.data?.swatch_text === 'OTHER'">
            {{artworkDetails?.garment_content_other}}
          </div>
        </li>
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Garment Color</div>
          <div class="font-xs" *ngIf="artworkDetails?.garment_color?.data?.color_name !== 'OTHER'">
            <ng-container *ngTemplateOutlet="colorBlock; context: {item: artworkDetails?.garment_color}"></ng-container>
          </div>
          <div class="font-xs" *ngIf="artworkDetails?.garment_color?.data?.color_name === 'OTHER'">
            {{artworkDetails?.garment_color_other}}</div>
        </li>
      </ul>

      <ul class="logo-nav row mt-3" *ngIf="artworkDetails.font_type">
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Font Type</div>
          <div class="font-xs">
            {{artworkDetails.font_type?.data?.name }}
          </div>
        </li>
      </ul>
      <ul class="logo-nav row mt-3" *ngIf="artworkDetails.font_type && artworkDetails.color_type">
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Color Type</div>
          <div class="font-xs">
            {{artworkDetails?.color_type?.data?.swatch_text }}
          </div>
        </li>
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Color</div>
          <div class="font-xs">
            <ng-container *ngTemplateOutlet="colorBlock; context: {item: artworkDetails?.color}"></ng-container>
          </div>
        </li>
      </ul>
      <ng-container *ngIf="!hideLineInfo">
        <ul class="logo-nav row mt-3" *ngIf="artworkDetails.emblem_line1">
          <li class="col-md-3">
            <div class="h6 mb-1 font-weight-bold">Line 1</div>
            <div class="font-xs">
              {{artworkDetails.emblem_line1 }}
            </div>
          </li>
        </ul>
        <ul class="logo-nav row mt-3" *ngIf="artworkDetails.emblem_line2">
          <li class="col-md-3">
            <div class="h6 mb-1 font-weight-bold">Line 2</div>
            <div class="font-xs">
              {{artworkDetails.emblem_line2 }}
            </div>
          </li>
        </ul>
        <ul class="logo-nav row mt-3" *ngIf="artworkDetails.emblem_line3">
          <li class="col-md-3">
            <div class="h6 mb-1 font-weight-bold">Line 3</div>
            <div class="font-xs">
              {{artworkDetails.emblem_line3 }}
            </div>
          </li>
        </ul>
      </ng-container>

      <ul class="logo-nav row mt-3" *ngIf="artworkDetails.fabric_type">
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Fabric Type</div>
          <div class="font-xs">
            {{artworkDetails.fabric_type.data.swatch_text }}
          </div>
        </li>
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Fabric Color</div>
          <div class="font-xs" *ngIf="artworkDetails?.fabric_color?.data?.color_name !== 'OTHER'">
            <ng-container *ngTemplateOutlet="colorBlock; context: {item: artworkDetails?.fabric_color}">
            </ng-container>
          </div>
          <div class="font-xs" *ngIf="artworkDetails?.fabric_color?.data?.color_name === 'OTHER'">
            {{artworkDetails?.fabric_color_other}}
          </div>
        </li>
      </ul>
      <ul class="logo-nav row mt-3" *ngIf="artworkDetails.finish">
        <li class="col-md-3">
          <div class="h6 mb-1 font-weight-bold">Finish</div>
          <div class="font-xs">
            {{artworkDetails.finish.data.swatch_text }}
          </div>
        </li>
      </ul>
      <ul class="logo-nav row mt-3">
        <li class="col-md-3" *ngIf="artworkDetails.laundry_type">
          <div class="h6 mb-1 font-weight-bold">Laundry Type</div>
          <div class="font-xs">
            {{artworkDetails.laundry_type?.data?.swatch_text }}
          </div>
        </li>
        <li class="col-md-3" *ngIf="artworkDetails.backing_type">
          <div class="h6 mb-1 font-weight-bold">Backing Type</div>
          <div class="font-xs">
            {{artworkDetails.backing_type?.data?.swatch_text }}
          </div>
        </li>
        <li class="col-md-3" *ngIf="artworkDetails.attachements">
          <div class="h6 mb-1 font-weight-bold">Attachments</div>
          <div class="font-xs">{{artworkDetails.attachements?.data?.swatch_text}}</div>
        </li>
      </ul>
      <ng-container *ngIf="artworkDetails && artworkDetails.color_details">
        <ng-container *ngTemplateOutlet="colorDetails; context: {key: 'color_details'}">
        </ng-container>
      </ng-container>

    </div>
    <ng-container *ngIf="artworkDetails.insert_text_thread_details">
      <div class="row">
        <div class="col-md-12 mt-2">
          <div class="font-weight-bold pt-2">Insert Information</div>
          <hr class="my-2">
        </div>
      </div>
      <div class="row" *ngFor="let item of artworkDetails.insert_text_thread_details">
        <div class="col-md-9">
          <app-order-shared-insert-list-details [editMode]="false" [item]="item"></app-order-shared-insert-list-details>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #colorDetails let-key="key">
    <ng-container *ngIf="key === 'color_details'">
      <div class="row">
        <div class="col-md-12 mt-2 font-weight-bold">
          Internal {{key === 'thread_details' ? 'Thread' : ''}} Colors
          <hr class="my-2">
        </div>
      </div>
      <ul class="logo-nav row mt-3">
        <li class="col-md-3" *ngIf="artworkDetails.no_of_colors">
          <div class="h6 mb-1 font-weight-bold"># of Colors</div>
          <div class="font-xs" *ngIf="artworkDetails.no_of_colors">{{artworkDetails.no_of_colors}}</div>
        </li>
      </ul>
    </ng-container>
    <div class="custom-scroll border mb-2">
      <table class="w-100 border-bottom stitch-table">
        <thead>
          <tr>
            <th class="ps-3 py-3" width="5%">#</th>
            <th class="py-3" width="27%">Color Type</th>
            <th class="py-3" width="35%">Color Code</th>
            <th class="py-3" width="30%">Color Description</th>
          </tr>
        </thead>
      </table>
      <div
        [ngClass]="{'table-wrapper-scroll-y': (artworkDetails && artworkDetails[key] && artworkDetails[key].length > 5)}">
        <table class="w-100 stitch-table" *ngIf="artworkDetails[key]">
          <tbody>
            <tr *ngFor="let item of artworkDetails[key]; let i = index">
              <td class="ps-3 py-2" width="5%">{{i+1}}</td>
              <td class="py-2" width="27%">{{item.color_type.data.swatch_text}}</td>
              <td class="py-2" width="35%">
                <ng-container *ngTemplateOutlet="colorBlock; context: {item: item.color}"></ng-container>
              </td>
              <td class="py-2" width="30%">{{item.color_description}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #colorBlock let-item="item">
  <ng-container *ngIf="item.data.swatch && item.data.swatch.url; else bgTemplate">
    <img [src]="item.data.swatch.url" class="img-swatch float-start w-18 border me-2">
  </ng-container>
  <ng-template #bgTemplate>
    <div *ngIf="item.data?.hex_code" class="float-start border p-2 me-2" [ngStyle]="{background: item.data?.hex_code}">
    </div>
  </ng-template>
  {{item.data?.color_name}}
</ng-template>
<div class="row mb-2 p-4 pb-2" *ngIf="config.search || config.additionalFilters">
  <div class="table-search-wrap col-lg-3 {{searchClassName}}" *ngIf="config.search">
    <input placeholder="Search" class="form-control" [formControl]="term" />
    <i class="bi bi-search search-icon" aria-hidden="true"></i>
  </div>
  <div class="col additional-filters" *ngIf="config.additionalFilters">
    <ng-content select="[additionalFilters]"></ng-content>
  </div>
  <div class="d-flex align-end"
    [ngClass]="{'col-md-8': (!config.additionalFilters && config.search), 'col-md-12': (!config.additionalFilters && !config.search), 'col-md-2': config.additionalFilters}">
    <div class="ps-0 d-flex align-items-center justify-content-center text-end" *ngIf="config.limit" translate>
      Show
      <select class="form-select form-control page-count me-0" (change)="onChangeTable($event, 'row')">
        <option *ngFor="let item of rows" [selected]="config.defaultRowCount === item" [value]="item">{{item}}</option>
      </select>
    </div>
  </div>

</div>
<div [appSpinner]="loading">
  <div [ngClass]="{'table-responsive': config.responsive}">
    <table class="table mb-2" ngClass="{{config.className || ''}}">
      <thead class="thead-default" ngClass="{{config.theadClassName || ''}}">
        <tr>
          <th *ngFor="let column of columns" class="{{column.className ? column.className : ''}}"
            [ngClass]="{'cursor-pointer': config.sorting && column.sort}">
            <ng-container *ngIf="column.type && column.type === 'checkbox'">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [checked]="allChecked" (change)="selectAll($event)"
                  id="table-checkbox-{{id}}">
                <label class="custom-control-label" for="table-checkbox-{{id}}">&nbsp;</label>
              </div>
            </ng-container>
            <span class="table-head position-relative d-inline-block cursor-pointer"
              (click)="column.sort && onChangeTable(column, 'sort')">
              {{column.title}}
              <ng-container *ngIf="config.sorting && column.sort">
                <i *ngIf="iconLibrary === 'font-awesome'" class="fa sort-icon position-absolute "
                [ngClass]="{'fa-sort-desc': column.sort_direction === 'desc', 'fa-sort-asc': column.sort_direction === 'asc'}"></i>
                <i *ngIf="iconLibrary === 'bootstrap-icons'" class="bi ps-1 position-absolute"
                [ngClass]="{'bi-chevron-down': column.sort_direction === 'desc', 'bi-chevron-up': column.sort_direction === 'asc'}"></i>
              </ng-container>
              
            </span>
          </th>
        </tr>
      </thead>
      <ng-content select="[table][body]"></ng-content>
    </table>
  </div>
  <div class="row" *ngIf="config.paging && totalResults > 0">
    <div class="col-md-12" ngClass="{{config.pagingClassName || ''}}">
      <div class="float-end">
        <app-pagination (pageChange)="onChangeTable($event, 'paging')" [pageSize]="tableData.rows"
          [collectionSize]="totalResults" [size]="'sm'" [(page)]="tableData.page" [boundaryLinks]="true" [maxSize]="5">
        </app-pagination>
      </div>
      <div class="float-start pagination-result-count">
        Showing <span>{{start}}</span> to <span>{{(start + results.length) -1}}</span> of <span>{{totalResults}}</span>
        entries
      </div>
    </div>
  </div>
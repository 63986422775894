<div [appSpinner]="isLoading"></div>
<div class="row">
  <div class="table-search-wrap position-relative col-12">
    <input placeholder="Search" class="form-control" [(ngModel)]="searchInput" (input)="onInputSearch()" />
    <i class="bi bi-search search-icon"></i>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-6 mb-2" *ngFor="let item of customerList" (click)="onClickCustomer(item)">
    <div class="customer-select border d-flex py-5 flex-column align-items-center justify-content-center cursor-pointer"
      [ngClass]="{'selected':selectedCustomerId === item.id}">
      <app-icon name="person-circle"></app-icon>
      <span class="font-xs">{{item.company_name}}</span>
    </div>
  </div>
  <div *ngIf="searchInput && searchInput.length > 0 && customerList.length <= 0" class="col-md-12 mt-4 mb-2">
    <p>No Results Found</p>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@salesonepro/services/auth.service';
import { StorageService } from '@salesonepro/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private storage: StorageService
  ) { }

  canActivate() {
    return this.authService.isAuthenticated.pipe(map(
      r => {
        const idToken = this.authService.getToken();
        const tokenExpired = idToken ? this.authService.isTokenExpired() : true;
        const selectedCustomer = this.storage.retrieve('selected_customer');
        if (r && !tokenExpired && selectedCustomer) {
          return true;
        }
        this.router.navigate(['/login']);
        return false;
      }),
      catchError((error: any) => {
        this.router.navigateByUrl('/login');
        return observableOf(false);
      }));
  }
}

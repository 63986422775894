import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// guards
import { AuthGuard } from '@core/auth/auth-guard.service';
import { NoAuthGuard } from '@core/auth/no-auth-guard.service';

// services
import { PasswordGuardService } from '@core/create-password/createpassword.guard';

// components
import { LayoutComponent } from '@layout/components/layout.component';
import { NotfoundComponent } from '@layout/components/notfound/notfound.component';
import { AuthComponent } from '@core/auth/auth.component';

export const AppRoutes: Routes = [

  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',

  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'request-login',
        loadChildren: () => import('./core/request-login/request-login.module').then(m => m.RequestLoginModule),
        canActivate: [PasswordGuardService],
        data: { breadcrumb: 'Request Login' }
      },
      {
        path: 'createpassword/:id',
        loadChildren: () => import('./core/create-password/create-password.module').then(m => m.CreatePasswordModule),
        canActivate: [PasswordGuardService],
        data: { breadcrumb: 'Create Password ' }
      },
      {
        path: 'account',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('./core/account/account.module').then(m => m.AccountModule),
        data: { breadcrumb: 'Account' }
      }
    ]
  },
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: '404',
    component: NotfoundComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  }
];


@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<ng-container *ngIf="form && form.get(['thread_details'])">
  <ng-container formGroupName="data">
    <div class="row" [formGroupName]="decorationMethod">
      <div class="col-md-9 mt-2 custom-scroll">
        <table class="table bg-gray mb-0" *ngIf="logoThreadDetails && logoThreadDetails.length > 0">
          <thead>
            <tr>
              <th width="5%">#</th>
              <th
                *ngIf="media_ext && media_ext === 'dst' && (decorationMethod === 'flat_embroidery' || decorationMethod === 'three_dimension_embroidery')">
                Stitches</th>
              <th width="20%" translate>Color Type</th>
              <th width="35%" translate>Color Code</th>
              <th width="30%" translate>Color Description</th>
            </tr>
          </thead>
        </table>
        <div class="table-responsive"
          [ngClass]="{'table-wrapper-scroll-y': (logoThreadDetails && logoThreadDetails.length > 5)}">
          <table class="table border patch-color" *ngIf="logoThreadDetails">
            <tbody formArrayName="thread_details">
              <ng-container *ngFor="let threadData of threadDetailsFormData.controls; let i=index">
                <tr [formGroupName]="i">
                  <td width="5%">{{i+1}}</td>
                  <td
                    *ngIf="media_ext && media_ext === 'dst' && (decorationMethod === 'flat_embroidery' || decorationMethod === 'three_dimension_embroidery')">
                    {{threadData.get('stitch_count').value}}
                  </td>
                  <td width="20%" formGroupName="color_type">
                    <select class="form-control" formControlName="id" (change)="threadChange($event, 'color_type', i)">
                      <option *ngFor="let item of threadTypeArray" [value]="item.id">{{item.data.swatch_text}}</option>
                    </select>
                    <app-validation-errors [submitted]="invalidForm"
                      [control]="threadData.get(['color_type', 'id'])"></app-validation-errors>
                  </td>
                  <td width="35%" class="position-relative" formGroupName="color">
                    <ng-select #ngSelectElement [items]="threadColorArray[i]" (change)="onThreadColorSelect($event, i)"
                      (close)="dstDrawReset()" (open)="focusChange(i)" formControlName="id" bindLabel="data.color_name"
                      [groupBy]="groupByFn" bindValue="id" placeholder="Select Color" class="color-code-wrap">
                      <ng-template ng-label-tmp let-item="item">
                        <ng-container *ngIf="item && item.data">
                          <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
                          </div>
                          {{item.data.color_name}}
                        </ng-container>
                        <ng-container *ngIf="!item.label && !item.data">
                          Select
                        </ng-container>
                      </ng-template>
                      <ng-template ng-optgroup-tmp let-item="item">
                        {{item['data.color_name']}}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
                        </div>
                        {{item.data.color_name}}
                      </ng-template>
                    </ng-select>
                    <app-validation-errors fieldName="Thread Color" [submitted]="invalidForm"
                      [control]="threadData.get(['color', 'id'])"></app-validation-errors>
                  </td>
                  <td width="30%">
                    <input type="text" class="form-control" formControlName="color_description"
                      (input)="threadChange($event, 'color_description', i)" />
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// components
import { ChangePswdDialogComponent } from '@core/account/information/change-pswd-dialog/change-pswd-dialog.component';
import { CustomerPopupComponent } from '@core/customer-popup/customer-popup.component';

// services
import { AuthService, StorageService } from '@salesonepro/services';
import { AccountService } from '@core/account/account.service';

// others
import { ModalPopupService, User } from '@salesonepro/index';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: User;
  sub: any;
  routerData: any;
  totalCustomer: number;
  userAcronym: any;
  isArtworkRequestForm: boolean;
  totalQty: number;
  unsubscriber$ = new Subject<void>();
  isCartLoading: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storage: StorageService,
    private modalService: ModalPopupService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.routerData = this.route.snapshot.firstChild;
    this.getCurrentUserInfo();
    if (this.storage.retrieve('customers', 'session')) {
      this.totalCustomer = this.storage.retrieve('customers', 'session').length;
    }
    this.checkRoute();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkRoute();
    });

    this.accountService.getCartSummary$
      .pipe(
        takeUntil(this.unsubscriber$)
      )
      .subscribe(response => {
        if (response) {
          this.totalQty = response.total_qty;
        }
      });

    this.accountService.isCartLoading
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(response => {
        this.isCartLoading = response;
      });
  }

  checkRoute() {
    const currentRoute = this.router.url;
    this.isArtworkRequestForm = currentRoute.includes('artwork-request-form');
    if (!['/account/buy-checkout', '/account/cart'].includes(currentRoute)) {
      this.getCartSummary();
    }
  }

  getCurrentUserInfo() {
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        if (userData && userData['name']) {
          const matches = userData['name'].match(/\b(\w)/g);
          this.userAcronym = matches.join('');
        }
      }
    );
  }

  logout() {
    this.authService.purgeAuth();
    this.accountService.isCartLoadingSubject.next(false);
    this.accountService.cartSummarySubject.next(null);
    this.router.navigate(['/', 'login']);
  }

  onClickChangeCustomer() {
    this.getPopUpWindow();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
  }

  getPopUpWindow() {
    const customer = this.storage.retrieve('customers', 'session');
    this.modalService.open(CustomerPopupComponent, {
      'title': 'CHANGE CUSTOMER ACCOUNT',
      'hideBackdrop': false,
      'size': 'lg',
      'position': 'top',
      'disableBackdropClick': true,
      'headerClass': 'bg-primary text-white',
      'input': { customer: customer, refresh: true },
      'hideCloseButton': false
    });
  }


  onClickChangePassword() {
    this.modalService.open(ChangePswdDialogComponent, {
      'title': 'CHANGE PASSWORD',
      'hideBackdrop': false,
      'size': 'md',
      'position': 'top',
      'disableBackdropClick': true,
      'headerClass': 'bg-primary text-white'
    });
  }

  getCartSummary() {
    this.accountService.loadCartSummary();
  }

}

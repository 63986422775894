<ng-container *ngIf="!loadingDecorationOptions">
  <ng-container formGroupName="data">
    <ng-container [formGroupName]="decorationMethod">
      <ng-container *ngFor="let item of lineTextList;let i = index">
        <div class="row" [id]="item.id">
          <div class="col-md-12 font-weight-bold">
            <div class="title-bg fw-600 py-2">
              <lib-salesonepro-icon class="me-2 cursor-pointer" name="times" *ngIf="i > 0"
                (click)="removeLineText(item)"></lib-salesonepro-icon>{{item.label}}
            </div>
          </div>
        </div>
        <div class="row" [formGroupName]="item.id">
          <div class="col-md-12">
            <div class="card card-body mb-2">
              <div class="row">
                <div class="col-md-4 my-2">
                  <label class="mb-2" id="text_{{item.id}}">{{item.label}} <small class="text-danger"
                      *ngIf="item.id === 'line1'">*</small></label>
                  <input type="text" id="text_{{item.id}}" class="form-control" formControlName="text">
                  <app-validation-errors [submitted]="invalidForm"
                    [control]="form.get(item.id).get('text')"></app-validation-errors>
                  <app-server-validation-error key="text" [errors]="error">
                  </app-server-validation-error>
                </div>
                <div class="col-md-4 my-2">
                  <label class="mb-2" for="logo_size">Lettering Size <small class="text-danger"
                      *ngIf="item.id === 'line1'">*</small></label>
                  <select class="form-control" formControlName="lettering_size">
                    <option value="">Select</option>
                    <option [value]="item.id" *ngFor="let item of decorationOptions['lettering_size']">
                      {{item.data.swatch_text}}
                    </option>
                  </select>
                  <app-validation-errors [submitted]="invalidForm"
                    [control]="form.get(item.id).get('lettering_size')"></app-validation-errors>
                  <app-server-validation-error [errors]="error"
                    key="lettering_size"></app-server-validation-error>
                </div>
                <div class="col-md-4 my-2">
                  <label class="mb-2" for="logo_size">Lettering Style <small class="text-danger"
                      *ngIf="item.id === 'line1'">*</small></label>
                  <select class="form-control" formControlName="lettering_style">
                    <option value="">Select</option>
                    <option [value]="item.id" *ngFor="let item of decorationOptions['lettering_style']">
                      {{item.data.swatch_text}}
                    </option>
                  </select>
                  <app-validation-errors [submitted]="invalidForm"
                    [control]="form.get(item.id).get('lettering_style')"></app-validation-errors>
                  <app-server-validation-error [errors]="error"
                    key="lettering_style"></app-server-validation-error>
                </div>
                <div class="col-md-4 my-2">
                  <label class="mb-2" for="logo_size">Case Style<small class="text-danger"
                      *ngIf="item.id === 'line1'">*</small></label>
                  <select class="form-control" formControlName="case_style">
                    <option value="">Select</option>
                    <option value="upper">Uppercase</option>
                    <option value="upper_lower">Uppercase/Lowercase</option>
                  </select>
                  <app-validation-errors [submitted]="invalidForm"
                    [control]="form.get(item.id).get('case_style')"></app-validation-errors>
                  <app-server-validation-error [errors]="error"
                    key="case_style"></app-server-validation-error>
                </div>
                <div class="col-md-4 my-2">
                  <label class="mb-2"># of Colors<small class="text-danger" *ngIf="item.id === 'line1'">*</small></label>
                  <input type="number" class="form-control" min="0" formControlName="no_of_colors"
                    (input)="onColorChange($event.target.value, item.id)">
                    <app-validation-errors [submitted]="invalidForm" 
                    [control]="form.get(item.id).get('no_of_colors')"></app-validation-errors>
                  <app-server-validation-error key="no_of_colors" [errors]="error">
                  </app-server-validation-error>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 my-1">
                  <table class="table table-bordered mb-0"
                    *ngIf="threadDetails[item.id] && threadDetails[item.id].length > 0">
                    <thead>
                      <tr>
                        <th width="5%">#</th>
                        <th width="20%" *ngIf="decorationMethodCode !== 'screen_printing'">Color Type</th>
                        <th width="35%">Color Code</th>
                        <th width="30%">Color Description</th>
                      </tr>
                    </thead>
                  </table>
                  <div
                    [ngClass]="{'table-wrapper-scroll-y': (threadDetails[item.id] && threadDetails[item.id].length > 5)}">
                    <table class="table table-bordered mb-0" *ngIf="threadDetails[item.id]">
                      <tbody formArrayName="thread_details">
                        <ng-container
                          *ngFor="let threadData of form.get([item.id, 'thread_details']).controls; let i=index">
                          <tr [formGroupName]="i">
                            <td width="5%">{{i+1}}</td>
                            <td width="20%" formGroupName="color_type">
                              <select class="form-control" formControlName="id"
                                (change)="threadChange($event, 'color_type', i, item.id)">
                                <option *ngFor="let item of threadTypeArray" [value]="item.id">{{item.data.swatch_text}}
                                </option>
                              </select>
                              <app-validation-errors [submitted]="formValidation"
                                [control]="threadData.get(['color_type', 'id'])"></app-validation-errors>
                            </td>
                            <td width="35%" class="position-relative" formGroupName="color">
                              <ng-select [items]="threadColorArray[item.id][i]"
                                (change)="onThreadColorSelect($event, i, item.id)" formControlName="id" bindLabel="code"
                                bindValue="id" placeholder="Select Color" class="color-code-wrap">
                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                  <!-- <div class="float-start color-badge p-2 me-2" [style.backgroundColor]="item.colorcode">
                            </div> -->
                                  <div class="float-start font-sm mt-1 align-self-center"
                                    *ngIf="item.data && item.data.color_name">
                                    <p class="mb-0">
                                      {{item.data.color_name}}
                                    </p>
                                  </div>
                                </ng-template>
                              </ng-select>
                            </td>
                            <td width="30%">
                              <input type="text" class="form-control" formControlName="color_description"
                                (input)="threadChange($event, 'color_description', i)" />
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 my-2">
                  <label>Notes</label>
                  <textarea class="form-control" formControlName="note" rows="4"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <a class="text-link cursor-pointer" id="add_line_text" *ngIf="lineTextList.length < 4"
        (click)="addLineText()">+ Add Another Line Text</a>
    </ng-container>
  </ng-container>
</ng-container>
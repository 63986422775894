export const environment = {
  production: false,
  siteKey: '6LcJZxAaAAAAAM-EGRxVZNPz1v9RQw016J4E_4AT',
  siteSecret: '6LcJZxAaAAAAAEeHsQ8EBBv8YWUZ0FFB-IRbQ8jb',
  apiUrl: 'https://penn-stage-cp.salesonepro.com/api/s',
  authUrl: 'https://penn-stage-cp.salesonepro.com/signin',
  clientId: 'P7K1ySH4KyTurv4PmTzzrbX3bdr82krV6grbrJQx',
  clientSecret: 'cJnit4TjlOy33EjaadfMW1bnZllWYPm186l3TilUCvenz0K2VwAOVkXItosXFQ5SfOOIWc8AOrD9JttTJY4eebu43PrprJkPcVpGYF0JZosxuT1SnRMFJlbQN0aUdCEt',
  tableRowLimit: '25'
};

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AccountService } from '@core/account/account.service';

@Component({
  selector: 'app-order-shared-artwork-listing',
  templateUrl: './listing.component.html'
})
export class SharedArtworkListingComponent implements OnChanges {
  @Input() artworkDetails;
  @Input() fullArtworkData;
  @Input() extension;
  @Input() decorationMethod;
  @Input() editMode: boolean = true;
  @Input() hideLineInfo: boolean;

  isThreadSupported: boolean;

  constructor(private accountService: AccountService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.decorationMethod && this.decorationMethod) {
      this.isThreadSupported = this.accountService.threadSupportedDecorationMethdods.includes(this.decorationMethod);
    }
  }

}

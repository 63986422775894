<div class="p-2 border mt-2 {{itemClass}}">
  <div class="row">
    <div class="col-md-4">
      <div class="line-left-side">
      <div class="mb-1 d-flex line-item-wrap" *ngFor="let text of item.lines;let i = index">
       <span class="line-item fw-300">Line {{i + 1}}</span>
        <span class="mb-0 line-item">{{text}}</span></div>
        </div>
    </div>
    <div class="col-md-4">
      <div class="cst-ms-mobile">
      <p class="mb-1 fw-300 d-flex align-items-start gap-1" *ngIf="item.insert_font_type.data.name !== 'OTHER'">
        <span class="flex-shrink-0">Font Type: </span><span class="fw-normal">{{item.insert_font_type.data.name}}</span>
      </p>
      <p class="mb-1 fw-300 d-flex align-items-start gap-1" *ngIf="item.insert_font_type.data.name === 'OTHER'">
        <span class="flex-shrink-0">Font Type: </span><span class="fw-normal">{{item.insert_font_type_other}}</span>
      </p>
      <p class="mb-0 fw-300 d-flex align-items-start gap-1">
        <span class="flex-shrink-0">Placement: </span><span class="fw-normal">{{item.insert_placement.data.value}}</span>
      </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="cst-ms-mobile">
      <p class="mb-1 fw-300 d-flex align-items-start gap-1">
       <span class="flex-shrink-0"> Color Type: </span><span class="fw-normal">{{item.color_type.data.value}}</span>
      </p>
      <div class="mb-0 d-flex">
        <div class="me-2 fw-300">Color: </div>
        <div class="d-flex align-items-start gap-1" *ngIf="item.color">
          <div class="border p-2 me-2" [style.backgroundColor]="item.color.data.hex_code">
          </div>
          <div class=""><span class="fw-normal">{{item.color.data.color_code}} - {{item.color.data.color_name}}</span></div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="editMode">
    <div class="col-md-3">
      <a class="cursor-pointer" (click)="onIsInsertClick(true)">Edit</a>
      <a class="cursor-pointer ms-2" (click)="removeInsertData()">Remove</a>
    </div>
  </div>
</div>
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// components
import { ModalPopupService } from '@salesonepro/components';
import { CustomerPopupComponent } from '../customer-popup/customer-popup.component';

// services
import { AuthService, StorageService } from '@salesonepro/services';
import { LoginService } from './login.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

  invalidAuthForm: boolean;
  invalidForgotPswdForm: boolean;
  formattedErrors = [];
  customers = [];
  isSpinnerVisible: boolean;
  formInvalid: boolean;
  forgotPassword: boolean;
  authForm: FormGroup;
  forgotPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private element: ElementRef,
    private router: Router,
    private authService: AuthService,
    private storage: StorageService,
    private loginService: LoginService,
    private modal: ModalPopupService
  ) {
    this.initializeForm();
  }

  initializeForm() {
    this.authForm = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'grant_type': 'password',
    });
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submitForm() {
    this.invalidAuthForm = true;
    if (this.authForm.valid) {
      this.formattedErrors = [];
      this.isSpinnerVisible = true;
      this.invalidAuthForm = false;
      this.formInvalid = false;
      this.authService.attemptAuth('', this.authForm.value).subscribe(data => {
          this.isSpinnerVisible = false;
          if (!this.storage.retrieve('default_customer')) {
            const params = new HttpParams();
            this.loginService.getCustomerInfo(params).subscribe(response => {
              if (response.length > 0) {
                this.storage.store('customers', response, 'session');
                if (response.length > 1){
                  this.getPopUpWindow(response);
                } else {
                  this.storage.store('default_customer', response[0]);
                  this.redirectToDashboard(response);
                }
              } else {
                this.authService.purgeAuth();
                this.router.navigateByUrl('/login').then(() => {
                  this.router.navigate(['/', 'login']);
                });
              }
            });
          } else {
            this.router.navigateByUrl('/');
          }
        }, err => {
          if (err && err.Title && err.Title === 'Request Throttle exception') {
            this.capcthaError();
          } else {
            this.formatError(err);
          }
          this.isSpinnerVisible = false;
        });
    } else {
      this.isSpinnerVisible = false;
      this.getInvalidFormFocus();
    }
  }

  formatError(errorList) {
    this.formattedErrors = [];
    if (typeof errorList === 'object') {
      Object.keys(errorList).forEach(key => {
        const value = errorList[key];
        if (typeof errorList[key] === 'string') {
          this.formattedErrors.push(value);
        } else {
          if (value.length > 0) {
            this.formattedErrors.push(value[0]);
          }
        }
      });
    } else {
      this.formattedErrors.push(errorList);
    }
  }

  getPopUpWindow(response) {
    this.modal.open(CustomerPopupComponent, {
      'title': 'SELECT CUSTOMER ACCOUNT TO MANAGE',
      'hideBackdrop': false,
      'size': 'lg',
      'position': 'top',
      'disableBackdropClick': true,
      'headerClass': 'bg-primary text-white',
      'input': {customer: response,refresh: false},
      'hideCloseButton': true
    });
  }

  getInvalidFormFocus() {
    const target = this.element.nativeElement.querySelector('.ng-invalid:not(form)');
    if (target) {
      target.focus();
    }
  }

  onClickForgotPwsd() {
    this.forgotPasswordForm.controls['email'].setValue('');
    this.forgotPassword = true;
  }

  goBack() {
    this.forgotPasswordForm.markAsUntouched();
    this.authForm.markAsUntouched();
    this.invalidAuthForm = false;
    this.invalidForgotPswdForm = false;
    this.forgotPassword = false;
  }

  capcthaError() {
    this.formInvalid = true;
    setTimeout(() => {
      this.captchaFocus();
    }, 800);
  }

  captchaFocus() {
    const captchaControl = this.element.nativeElement.querySelector('.recaptcha');
    if (captchaControl) {
      captchaControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      captchaControl.focus();
    }
  }

  onClickRessetPwsd(event) {
    this.invalidForgotPswdForm = true;
    if (this.forgotPasswordForm.valid) {
      this.invalidForgotPswdForm = false;
      this.loginService.forgotPassword(event).subscribe(response => {
        this.goBack();
      });
    } else {
      this.getInvalidFormFocus();
    }
  }

  redirectToDashboard(response){
    this.storage.store('selected_customer', response[0]);
    this.router.navigateByUrl('/account/dashboard');
  }

}
<div class="footer-wrap">
    <!-- <div class="footer-top py-5 d-none">
    <div class="row footer">
        <div class="col-md-2 footer-links-wrap">
            <h6>Company Info</h6>
            <ul class="list-unstyled fw-light">
                <li>
                    <a href="">Contact Us</a>
                </li>
                <li>
                    <a href="">Blog</a>
                </li>
                <li>
                    <a href="">Careers</a>
                </li>
                <li>
                    <a href="">Site Map</a>
                </li>
            </ul>
        </div>
        <div class="col-md-2 footer-links-wrap">
            <h6>Quick links</h6>
            <ul class="list-unstyled fw-light">
                <li>
                    <a href="">Request a Login</a>
                </li>
                <li>
                    <a href="">About Penn Emblem</a>
                </li>
                <li>
                    <a href="">FAQ</a>
                </li>
                <li>
                    <a href="">News & Events</a>
                </li>
            </ul>
        </div>
        <div class="col-md-3 footer-links-wrap">
            <h6 class="d-flex"><i class="bi bi-geo-alt-fill pe-3"></i>Pennsylvania office</h6>
            <div class="location fw-light">
                2577 Interplex Drive, Suite A <br>
                Trevose, PA 19053, <br>
                Phone/Fax: 800-793-7366<br>
            </div>
        </div>
        <div class="col-md-3 footer-links-wrap">
            <h6 class="d-flex"><i class="bi bi-geo-alt-fill pe-3"></i>California Office</h6>
            <div class="location fw-light">
                11199 Inland Ave<br>
                Mira Loma, CA 91752<br>
                Phone/Fax: 800-775-7366<br>
            </div>
        </div>
        <div class="col-md-2 footer-logo-grid">
            <div class="footer-logo-wrap d-flex flex-wrap align-items-center">
                <span class="footer-logo"><img alt="wbenc" title="wbenc" src="assets/images/footer/wbenc.png"></span>
                <span class="footer-logo"><img alt="wrap" title="wrap" src="assets/images/footer/wrap.png"></span>
                <span class="footer-logo"><img alt="ctpat" title="ctpat" src="assets/images/footer/ctpat.jpg"></span> 
                <span class="footer-logo"><img alt="asi" title="asi" src="assets/images/footer/icon-3.png"></span>
                <span class="footer-logo"><img alt="ppai" title="ppai" src="assets/images/footer/ppai.png"></span>
                <span class="footer-logo"><img alt="APlus" title="APlus" src="assets/images/footer/APlus_Light.png"></span>
            </div>

        </div>
    </div>
</div> -->
<div class="footer-bottom py-sm-4 py-2">
        <div class="footer d-flex align-items-center justify-content-between">
            <div class="socialgroup-wrap d-flex align-items-center">
                <span class="connect-with-title text-uppercase">Connect with us:</span>
                <div class="socialgroup ms-4 ">
                    <div class="social-icon d-flex">
                        <a href="https://www.facebook.com/PennEmblemCompany/?ref=ts" target="_blank"><i class="bi bi-facebook"></i></a>
                        <a href="https://twitter.com/pennemblem" target="_blank">	<i class="bi bi-twitter"></i></a>
                        <a href="http://instagram.com/pennemblemcompany" target="_blank"><i class="bi bi-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/penn-emblem/" target="_blank"><i class="bi bi-linkedin"></i></a>
                        <a href="https://www.pinterest.com/PennEmblemCompany/_saved/" target="_blank"><i class="bi bi-pinterest"></i></a>
                    </div>
                </div>   
            </div>
            <p class="copyright mb-0"> &copy; Copyright {{ today | date: 'yyyy' }}, Penn Emblem All Rights Reserved</p>

        </div>
</div>
</div>
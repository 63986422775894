import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

// components
import { ModalPopupOverlayRef } from '@salesonepro/components';
import { StorageService } from '@salesonepro/services';

// services
import { AccountService } from '@core/account/account.service';

@Component({
  selector: 'app-customer-popup',
  templateUrl: './customer-popup.component.html',
  styleUrls: ['./customer-popup.component.scss']
})
export class CustomerPopupComponent {

  masterCustomerList = [];
  customerList = [];
  searchInput: string;
  selectedCustomerId: any;
  isLoadRefresh: boolean ;
  isLoading:boolean;

  constructor(
    @Inject(ModalPopupOverlayRef) public modalData,
    private router: Router,
    public dialogRef: ModalPopupOverlayRef<CustomerPopupComponent>,
    private storage: StorageService,
    private accountService: AccountService,
  ) {
    this.masterCustomerList = modalData.data.input.customer;
    this.isLoadRefresh = modalData.data.input.refresh;
    this.customerList = this.masterCustomerList;
    if (this.storage.retrieve('selected_customer')) {
      this.selectedCustomerId = this.storage.retrieve('selected_customer').id;
    }
  }

  onClickCustomer(selectedCustomer) {
    this.isLoading = true;
    if (this.isLoadRefresh) {
      this.storage.clear('account-details');
    }
    this.storage.clear('cart-id');
    this.storage.store('default_customer', selectedCustomer);
    this.storage.store('selected_customer', selectedCustomer);
    setTimeout(() => {
      this.accountService.loadCartSummary();
    }, 100);

    if (this.isLoadRefresh) {
      window.location.reload();
    } else {
      this.router.navigateByUrl('/account/dashboard');
    }
    this.dialogRef.close(null);
  }

  onInputSearch(){
    if (this.searchInput) {
      this.customerList = this.masterCustomerList.filter(
        (item) => item.company_name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
    } else {
      this.filterCustomer();
    }
  }

  filterCustomer() {
    this.customerList = this.masterCustomerList;
  }

}
<form [formGroup]="changePswdForm" (ngSubmit)="onClickChangePassword()">
  <div class="row" [appSpinner]="isLoading">
    <div class="col-lg-12 mb-2">
      <label class="font-xs mb-2">Current Password*</label>
      <div class="input-group rounded-2 form-custom mb-1">
        <span class="input-group-text"><i class="bi bi-lock"></i></span>

        <input type="password" class="form-control py-2" formControlName="current_password" placeholder="Current Password*">

      </div>
      <div>
        <app-validation-errors [submitted]="invalidPswdForm"
          [control]="changePswdForm.get('current_password')"></app-validation-errors>
      </div>
    </div>

    <div class="col-lg-12 mb-2">
      <label class="font-xs mb-2">New Password*</label>
      <div class="input-group rounded-2 form-custom mb-1">
        <span class="input-group-text"><i class="bi bi-lock"></i></span>
          <input type="password" class="form-control py-2" formControlName="new_password" placeholder="New Password*">
      </div>
      <div>
        <app-validation-errors [submitted]="invalidPswdForm"
          [control]="changePswdForm.get('new_password')"></app-validation-errors>
      </div>
    </div>

    <div class="col-lg-12">
      <label class="font-xs mb-2">Confirm New Password*</label>
      <div class="input-group rounded-2 form-custom mb-1">
        <span class="input-group-text"><i class="bi bi-lock"></i></span>      
          <input type="password" class="form-control py-2" formControlName="confirm_password"
            placeholder="Confirm New Password*">    
      </div>
      <div>
        <app-validation-errors [submitted]="invalidPswdForm"
          [control]="changePswdForm.get('confirm_password')"></app-validation-errors>
        <small class="text-danger" *ngIf="changePswdForm.errors?.doesMatchPassword">Password does not
          match.</small>
      </div>
    </div>

    <div class="action-btn-wrap mt-3" align="right">
      <button type="submit" class="btn btn-primary">Change Password</button>
    </div>

  </div>
</form>
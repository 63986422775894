<div class="row" *ngIf="form.get(['data', decorationMethod])">
  <div class="col-md-3 my-2" *ngIf="decorationSchemaKeys.includes('logo_width')">
    <label for="logo_width" class="font-xs mb-2">Logo Size (Width) <small class="text-danger">*</small></label>
    <input type="number" min="0" [readonly]="isReadOnly" id="logo_width" class="form-control"
      (input)="onDimensionChange($event.target.value, 'logo_width')"
      [formControl]="form.get(['data', decorationMethod, 'logo_width'])"
      [placeholder]="checkProportional('logo_width') ? 'Proportional' : ''">
    <small *ngIf="checkProportional('logo_width')" class="form-text text-primary">Proportional to height.</small>
    <app-validation-errors [submitted]="invalidForm"
      [control]="form.get(['data', decorationMethod, 'logo_width'])"></app-validation-errors>
    <app-server-validation-error [errors]="error" key="logo_width"></app-server-validation-error>
  </div>
  <div class="col-md-3 my-2" *ngIf="decorationSchemaKeys.includes('logo_size')">
    <label for="logo_size" class="font-xs mb-2">Logo Size (Height) <small class="text-danger">*</small></label>
    <input type="number" min="0" [readonly]="isReadOnly" id="logo_size" class="form-control"
      (input)="onDimensionChange($event.target.value, 'logo_size')"
      [formControl]="form.get(['data', decorationMethod, 'logo_size'])"
      [placeholder]="checkProportional('logo_size') ? 'Proportional' : ''">
    <small *ngIf="checkProportional('logo_size')" class="form-text text-primary">Proportional to width.</small>
    <app-validation-errors [submitted]="invalidForm"
      [control]="form.get(['data', decorationMethod, 'logo_size'])"></app-validation-errors>
    <app-server-validation-error [errors]="error" key="logo_size"></app-server-validation-error>
  </div>
  <div class="col-md-3 my-2" *ngIf="decorationSchemaKeys.includes('no_of_colors')
    && decorationSchema['properties']['thread_details']
    && form.get(['data', decorationMethod, 'no_of_colors'])">
    <label for="no_of_colors" class="font-xs mb-2"># of Colors <small class="text-danger">*</small></label>
    <input type="number" min="0" [readonly]="isReadOnly" id="no_of_colors" class="form-control"
      [formControl]="form.get(['data', decorationMethod, 'no_of_colors'])">
    <app-validation-errors [submitted]="invalidForm"
      [control]="form.get(['data', decorationMethod, 'no_of_colors'])"></app-validation-errors>
    <app-server-validation-error [errors]="error" key="no_of_colors"></app-server-validation-error>
  </div>
</div>
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, ControlContainer, Validators, FormControl, FormArray } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { isEmpty } from '@salesonepro/utils';
import { AccountService } from '@core/account/account.service';

@Component({
  selector: 'app-order-shared-artwork-additional-form',
  templateUrl: './additional-form.component.html',
  styleUrls: ['./additional-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
})
export class SharedArtworkAdditionalFormComponent implements OnChanges {
  @Input() decorationMethod: string;
  @Input() logoDetails;
  @Input() logoThreadDetails;
  @Input() dstInfo;
  @Input() error;
  @Input() invalidForm: boolean;
  @Input() threadColors: any = [];
  @Input() threadTypeArray: any = {};
  @Input() defaultThreadType: number;
  @Input() orderOptions: any;
  @Input() loadingDecorationOptions: boolean;
  @Input() decorationOptions: any;
  @Input() decorationSchema: any;
  @Input() isBuyProcess: boolean;
  @Input() hideInsertQty: boolean;
  @Input() templateProcessLoading: boolean;
  @Input() newInsertInfo = [];
  @Input() noOfLines: number = 3;
  @Output() insertChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() insertQtyChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() downloadTemplate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() uploadInsertTemplate: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('insertBlock', { static: false }) insertBlock: ElementRef;


  form: FormGroup;

  borderColorArray: any = [];
  isBorderColorOther: boolean;

  fabricColorArray: any = [];
  isFabricColorOther: boolean;

  threadColorArray: any = [];
  selectedThreadColor: any = [];
  colorDetailsExist: boolean;
  selectedLaundryType: number;

  isGarmentColorOther: boolean;
  isGarmentTypeOther: boolean;
  isGarmentContentOther: boolean;

  showEmblemShape: boolean;

  data: any;
  isSizeOther: boolean;
  customerInfo: any;
  threadDetailsKey: string;
  haveFontType: boolean;
  haveSize: boolean;

  decorationSchemaKeys = [];
  isInsert: boolean;
  insertTextData = [];

  customWidthHeightList = [
    'penn_weave',
    'woven_label',
    'penn_trans_plh',
    'penn_trans_pli',
    'penn_flex_badge',
    'penn_flex_logo',
    'ij_blank',
    'es_pg_letter',
    'es_eg_letter',
    'etched_leather',
    'handcut'
  ];
  isEmblemShapeOther: boolean;
  colorArray: any = [];

  colorOptions = {};
  isFontTypeOther = {};
  insertChecked: boolean;


  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
    private accountService: AccountService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.decorationMethod && this.decorationMethod && this.form) {
      this.form = null;
    }
    if (changes.decorationOptions && this.decorationOptions) {
      const data = this.logoDetails && this.logoDetails['data'][this.decorationMethod] ? this.logoDetails['data'][this.decorationMethod] : null;
      this.data = data;
      if (!this.form) {
        const isInsertDecorationList = this.accountService.isInsertDecorationList;
        this.isInsert = isInsertDecorationList.includes(this.decorationMethod) ? true : false;
        this.form = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
        if (!this.form) {
          let dataFormGp = this.parent.form.get('data') as FormGroup;
          if (!dataFormGp) {
            this.parent.form.addControl('data', this.fb.group({}));
            dataFormGp = this.parent.form.get('data') as FormGroup;
          }
          dataFormGp.addControl(this.decorationMethod, this.fb.group({}));
          this.form = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
        }


        this.setAccountDetails();

        this.decorationSchemaKeys = this.decorationSchema ? Object.keys(this.decorationSchema['properties']) : [];

        if (this.decorationSchema['properties']['total_stitch_count']) {
          this.form.addControl('total_stitch_count', new FormControl(null));
        }

        if (this.decorationSchema['properties']['is_tape_edit']) {
          this.form.addControl('is_tape_edit', new FormControl(false));
        }

        if (this.decorationSchema['properties']['is_digitizing_required']) {
          this.form.addControl('is_digitizing_required', new FormControl(false));
        }
        if (this.decorationSchema['properties']['emblem_shape']) {
          this.form.addControl('emblem_shape', new FormControl(data?.emblem_shape?.id || ''));
          this.form.addControl('emblem_shape_other', new FormControl(data?.emblem_shape_other || ''));
        }

        if (this.decorationSchema['properties']['size']) {
          this.form.addControl('custom_die_width', new FormControl(data?.custom_die_width || null));
          this.form.addControl('custom_die_height', new FormControl(data?.custom_die_height || null));
          this.form.addControl('size', new FormControl(data?.size?.id || null, [Validators.required]));
          if (data && data.size) {
            this.onSizeChange();
          }
        } else if (this.decorationSchema['properties']['emblem_shape']) {
          this.showEmblemShape = true;
        }

        if (this.decorationSchema['properties']['border_type']) {
          this.form.addControl('border_type', new FormControl(data?.border_type?.id || '', [Validators.required]));
        }
        if (this.decorationSchema['properties']['border_color_type']) {
          this.form.addControl('border_color_type', new FormControl(data?.border_color_type?.id || '', [Validators.required]));
        }
        if (this.decorationSchema['properties']['border_color']) {
          this.form.addControl('border_color', new FormControl(data?.size?.id || '', [Validators.required]));
        }
        if (this.decorationSchema['properties']['border_color_other']) {
          this.form.addControl('border_color_other', new FormControl(data?.border_color_other || ''));
        }

        if (this.decorationSchema['properties']['fabric_type']) {
          this.form.addControl('fabric_type', new FormControl(data?.fabric_type?.id || '', [Validators.required]));
          this.form.addControl('fabric_color', new FormControl(data?.fabric_color?.id || '', [Validators.required]));
          this.form.addControl('fabric_color_other', new FormControl(data?.fabric_color_other || ''));
          this.isFabricColorOther = data?.fabric_color_other ? true : false;
        }

        if (this.decorationSchema['properties']['finish']) {
          this.form.addControl('finish', new FormControl(data?.finish?.id || '', [Validators.required]));
        }

        if (this.decorationSchema['properties']['attachements']) {
          this.form.addControl('attachements', new FormControl(data?.attachements?.id || '', [Validators.required]));
        }

        if (this.decorationSchema['properties']['garment_type']) {
          this.form.addControl('garment_type', new FormControl(data?.garment_type?.id || '', [Validators.required]));
          this.form.addControl('garment_type_other', new FormControl(data?.garment_type_other || ''));
          this.form.addControl('garment_content', new FormControl(data?.garment_content?.id || '', [Validators.required]));
          this.form.addControl('garment_content_other', new FormControl(data?.garment_content_other || ''));
          this.form.addControl('garment_color', new FormControl(data?.garment_color?.id || '', [Validators.required]));
          this.form.addControl('garment_color_other', new FormControl(data?.garment_color_other || ''));
        }

        if (this.decorationSchema['properties']['backing_type']) {
          this.form.addControl('backing_type', new FormControl(data?.backing_type?.id || '', [Validators.required]));
        }

        if (this.decorationSchema['properties']['fold']) {
          this.form.addControl('fold', new FormControl(data?.fold?.id || '', [Validators.required]));
        }

        if (this.decorationSchema['properties']['laundry_type']) {
          this.form.addControl('laundry_type', new FormControl(data?.laundry_type?.id || '', [Validators.required]));
          this.selectedLaundryType = data?.laundry_type?.id ?? null;
        }

        if (this.decorationSchema['properties']['font_type'] && !this.isInsert) {
          this.form.addControl('font_type', new FormControl(data?.font_type?.id || '', [Validators.required]));
        }

        if (this.decorationSchemaKeys.includes('color_type') && this.decorationSchemaKeys.includes('color') && this.decorationSchemaKeys.includes('font_type')) {
          this.form.addControl('color_type', new FormControl(data?.color_type?.id || '', [Validators.required]));
          this.form.addControl('color', new FormControl(data?.color?.id || '', [Validators.required]));
        }

        if (this.decorationSchema['properties']['emblem_line1']) {
          this.form.addControl('emblem_line1', new FormControl(data?.emblem_line1 && data.emblem_line1 !== 'one_program' || '', [Validators.required]));
        }

        if (this.decorationSchema['properties']['emblem_line2']) {
          this.form.addControl('emblem_line2', new FormControl(data?.emblem_line2 || ''));
        }

        if (this.decorationSchema['properties']['emblem_line3']) {
          this.form.addControl('emblem_line3', new FormControl(data?.emblem_line3 || ''));
        }

        if (this.decorationSchema['properties']['insert_text_thread_details']) {
          if (data && data['insert_text_thread_details']) {
            this.form.addControl('insert_text_thread_details', this.fb.array([]));
            const formArr = this.form.get('insert_text_thread_details') as FormArray;
            formArr.controls = [];
            data['insert_text_thread_details'].forEach(element => {
              const textThreadFormGroup = this.initInsertForm(element);

              element.lines.forEach(line => {
                const lineFormArray = textThreadFormGroup.get('lines') as FormArray;
                lineFormArray.push(new FormControl(line));
              });
              formArr.push(textThreadFormGroup);
              const index = formArr.length - 1;
              if (element && element.qty > 0) {
                this.calculateTotalInsertQty();
              }
              this.onColorTypeChange(textThreadFormGroup, index);
              this.onFontTypeChange(textThreadFormGroup, index);
            });
            this.onIsInsertChange(true);
          } else if (this.isInsert && !this.isBuyProcess) {
            const textThreadFormGroup = this.initInsertForm();
            const lineFormArray = textThreadFormGroup.get('lines') as FormArray;
            lineFormArray.push(new FormControl('', Validators.required));
            this.form.addControl('insert_text_thread_details', this.fb.array([textThreadFormGroup]));
            this.form.get('insert_text_thread_details').disable();
          }
        }

        this.colorDetailsExist = false;
        this.threadDetailsKey = 'color_details';
        if (this.decorationSchema['properties']['color_details']) {
          this.colorDetailsExist = true;
          this.form.addControl(this.threadDetailsKey, new FormArray([]));
          this.logoThreadDetails = data && data[this.threadDetailsKey] ? data[this.threadDetailsKey] : [];
        }

        if (this.decorationSchema['properties']['no_of_colors'] && this.decorationSchema['properties']['color_details']) {
          this.form.addControl('no_of_colors', new FormControl(data?.no_of_colors || '', [Validators.required, Validators.min(1)]));
          if (data && data.no_of_colors) {
            this.onColorChange(data.no_of_colors);
          }
          this.form.get('no_of_colors').valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged())
            .subscribe((value) => {
              this.onColorChange(value);
            });
        }

        if (this.decorationSchema['properties']['lines']) {
          this.form.addControl('lines', new FormArray([]));
          const linesFormArray = this.form.get('lines') as FormArray;
          linesFormArray.controls.length = 0;
          if (data && data['lines']) {
            data['lines'].forEach(element => {
              const lineCtrl = new FormControl(element, Validators.required);
              linesFormArray.push(lineCtrl);
            });
          } else {
            const lineCtrl = new FormControl('', Validators.required);
            linesFormArray.push(lineCtrl);
          }
        }
        if (this.customWidthHeightList.includes(this.decorationMethod) && !this.decorationSchema['properties']['size']) {
          this.form.addControl('custom_die_width', new FormControl(data && data.custom_die_width ? data.custom_die_width : null));
          this.form.addControl('custom_die_height', new FormControl(data && data.custom_die_height ? data.custom_die_height : null));
          this.isSizeOther = true;
        }
      }
      if (data && this.decorationSchemaKeys.length > 0) {
        if (this.decorationSchema['properties']['emblem_shape']) {
          this.onEmblemShapeChange();
        }
        if (this.decorationSchema['properties']['border_color_type']) {
          this.onBorderColorTypeChange(data);
        }
        if (this.decorationSchema['properties']['border_color']) {
          this.onBorderColorChange();
        }
        if (this.decorationSchema['properties']['fabric_type']) {
          this.onFabricTypeChange(data);
        }
        if (this.decorationSchema['properties']['color_type'] && this.decorationSchema['properties']['font_type']) {
          this.onFontColorTypeChange(true);
        }
        if (this.decorationSchema['properties']['garment_type']) {
          this.onGarmentTypeChange();
        }
        if (this.decorationSchema['properties']['garment_content']) {
          this.onGarmentContentChange();
        }
        if (this.decorationSchema['properties']['garment_color']) {
          this.onGarmentColorChange();
        }
      }
    }

    if (changes.newInsertInfo && this.newInsertInfo.length > 0) {
      if (this.decorationSchema['properties']['insert_text_thread_details']) {
        const formArr = this.form.get('insert_text_thread_details') as FormArray;
        this.newInsertInfo.forEach(element => {
          const textThreadFormGroup = this.initInsertForm(element);
          element.lines.forEach(line => {
            const lineFormArray = textThreadFormGroup.get('lines') as FormArray;
            lineFormArray.push(new FormControl(line));
          });
          formArr.push(textThreadFormGroup);
          const index = formArr.length - 1;
          if (element && element.qty > 0) {
            this.calculateTotalInsertQty();
          }
          this.onColorTypeChange(textThreadFormGroup, index);
          this.onFontTypeChange(textThreadFormGroup, index);
        });
      }
    }
  }

  setAccountDetails() {
    this.accountService.getAccountDetails().subscribe(response => {
      this.customerInfo = response;
      this.form.addControl('customer_type', new FormControl(this.customerInfo.details.group));
    });
  }

  addNewInsertThread() {
    const textThreadFormGroup = this.initInsertForm();
    const lineFormArray = textThreadFormGroup.get('lines') as FormArray;
    lineFormArray.push(new FormControl('', Validators.required));
    const formArr = this.form.get('insert_text_thread_details') as FormArray;
    formArr.push(textThreadFormGroup);
    setTimeout(() => {
      const el = document.querySelector('#insert_form_' + (formArr.controls.length - 1));
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  removeInsertInfo(index) {
    const formArr = this.form.get('insert_text_thread_details') as FormArray;
    formArr.removeAt(index);
  }

  initInsertForm(data?) {
    return this.fb.group({
      'insert_font_type': [data && data.insert_font_type ? data.insert_font_type.id : null, Validators.required],
      'insert_placement': [data && data.insert_placement ? data.insert_placement.id : '', Validators.required],
      'insert_font_type_other': [data && data.insert_font_type_other ? data.insert_font_type_other : ''],
      'lines': this.fb.array([]),
      'color_type': [data && data.color_type ? data.color_type.id : '', Validators.required],
      'color': [data && data.color ? data.color.id : '', Validators.required],
      'qty': [data && data.qty ? data.qty : 1, [Validators.required, Validators.min(1)]],
    });
  }

  onColorTypeChange(form, index) {
    const value = form.get('color_type').value;
    const option = this.decorationOptions['color_type'].find(x => parseInt(x.id) === parseInt(value));
    if (option) {
      this.colorOptions[index] = this.decorationOptions['color'].filter(x => x.data.color_type === option.data.value);
    } else {
      this.colorOptions[index] = [];
    }
  }

  onFontTypeChange(form, index) {
    const fontTypeId = form.get('insert_font_type').value;
    const fontType = this.decorationOptions['insert_font_type'].find(x => x.id === fontTypeId);
    if (fontType && fontType.data && fontType.data.name === 'OTHER') {
      this.isFontTypeOther[index] = true;
      form.get('insert_font_type_other').setValidators([Validators.required]);
    } else {
      this.isFontTypeOther[index] = false;
      form.get('insert_font_type_other').clearValidators();
    }
    form.get('insert_font_type_other').updateValueAndValidity();
  }

  onIsInsertChange(checked: boolean) {
    this.insertChecked = checked;
    this.insertChange.emit(this.insertChecked);
    if (this.insertChecked) {
      this.form.get('insert_text_thread_details').enable();
    } else {
      this.form.get('insert_text_thread_details').disable();
    }
  }

  addInsertLineText(linesFormArray) {
    const lineCtrl = new FormControl('', Validators.required);
    linesFormArray.push(lineCtrl);
  }

  removeLineText(linesFormArray, index) {
    linesFormArray.removeAt(index);
  }

  calculateTotalInsertQty() {
    const insertThreadDetails = this.form.get('insert_text_thread_details').value;
    let totalQty = 0;
    insertThreadDetails.forEach(element => {
      if (element['qty'] && element['qty'] > 0) {
        totalQty += parseInt(element['qty'], 10);
      }
    });
    this.insertQtyChange.emit(totalQty);
  }

  onSizeChange() {
    if (this.form.get('size').value) {
      const size = this.decorationOptions['size'].find(x => x.id.toString() === this.form.get('size').value.toString());
      const dieWidthCtrl = this.form.get('custom_die_width');
      const dieHeightCtrl = this.form.get('custom_die_height');

      const emblemShapeCtrl = this.form.get('emblem_shape');
      if (size && size.data.value === 'OTHER') {
        this.isSizeOther = true;
        this.showEmblemShape = true;
        dieWidthCtrl.setValidators([Validators.required]);
        dieHeightCtrl.setValidators([Validators.required]);
        emblemShapeCtrl.setValidators([Validators.required]);
        emblemShapeCtrl.enable();
      } else {
        this.showEmblemShape = false;
        this.isSizeOther = false;
        dieWidthCtrl.clearValidators();
        dieHeightCtrl.clearValidators();
        emblemShapeCtrl.clearValidators();
        emblemShapeCtrl.disable();
      }
      dieWidthCtrl.updateValueAndValidity();
      dieHeightCtrl.updateValueAndValidity();
      emblemShapeCtrl.updateValueAndValidity();
      this.form.updateValueAndValidity();
    } else {
      this.showEmblemShape = false;
      this.isSizeOther = false;
    }
  }

  onBorderTypeChange() {
    if (this.decorationSchema['properties']['border_color_type']) {
      this.form.get('border_color_type').setValue('');
    }
    if (this.decorationSchema['properties']['border_color']) {
      this.form.get('border_color').setValue('');
    }
    if (this.decorationSchema['properties']['border_color_other']) {
      this.form.get('border_color_other').setValue('');
    }
  }

  onFontColorTypeChange(noReset?: boolean) {
    if (!noReset) {
      this.form.get('color').setValue('');
    }

    const colorTypeId = this.form.get('color_type').value;
    if (colorTypeId) {
      const colorType = this.decorationOptions['color_type'].find(x => parseInt(x.id) === parseInt(colorTypeId));
      if (colorType) {
        this.colorArray = this.decorationOptions['color'].filter(
          x => x.data.color_type === colorType.data.value
        );
      }
    }
  }

  onEmblemShapeChange() {
    const value = this.form.get('emblem_shape')?.value;
    const option = this.decorationOptions['emblem_shape'].find(x => parseInt(x.id) === parseInt(value));
    if (option && option.data.value === 'OTHER') {
      this.isEmblemShapeOther = true;
      this.form.get('emblem_shape_other').setValidators([Validators.required]);
    } else {
      this.isEmblemShapeOther = false;
      this.form.get('emblem_shape_other').clearValidators();
    }
    this.form.get('emblem_shape_other').updateValueAndValidity();
  }

  onBorderColorTypeChange(data?) {
    if (data && data.border_color_type) {
      this.form.get('border_color_type').setValue(data.border_color_type.id);
    }
    const colorTypeId = this.form.get('border_color_type').value;
    const colorType = this.decorationOptions['border_color_type'].find(x => parseInt(x.id) === parseInt(colorTypeId));
    const borderTypeid = this.form.get('border_type').value;
    const borderType = this.decorationOptions['border_type'].find(x => parseInt(x.id) === parseInt(borderTypeid));
    if (colorTypeId && borderTypeid) {
      this.borderColorArray = this.decorationOptions['border_color'].filter(
        x => x.data.color_type === colorType.data.value
          && x.data.border_type.includes(borderType.data.value)
      );
    }
    this.isBorderColorOther = false;
    if (!data) {
      this.form.get('border_color').setValue('');
      if (this.form.get('border_color_other')) {
        this.form.get('border_color_other').setValue('');
      }
    } else {
      this.form.get('border_color').setValue(data?.border_color?.id);
      if (this.form.get('border_color_other')) {
        this.form.get('border_color_other').setValue(data.border_color_other);
      }
    }
  }

  onBorderColorChange() {
    const color = this.form.get('border_color').value;
    const colorValue = this.borderColorArray.find(x => parseInt(x.id) === parseInt(color));
    if (colorValue && colorValue.data.color_name === 'OTHER') {
      this.isBorderColorOther = true;
      this.form.get('border_color_other').setValidators([Validators.required]);
      this.form.get('border_color_other').updateValueAndValidity();
    } else if (this.decorationSchema['properties']['border_color_other']) {
      this.isBorderColorOther = false;
      this.form.get('border_color_other').clearValidators();
      this.form.get('border_color_other').updateValueAndValidity();
    }
  }

  onFabricColorChange() {
    const color = this.form.get('fabric_color').value;
    const colorValue = this.fabricColorArray.find(x => parseInt(x.id) === parseInt(color));
    if (colorValue && colorValue.data.color_name === 'OTHER') {
      this.isFabricColorOther = true;
      this.form.get('fabric_color_other').setValidators([Validators.required]);
    } else {
      this.isFabricColorOther = false;
      this.form.get('fabric_color_other').clearValidators();
    }
    this.form.get('fabric_color_other').updateValueAndValidity();
  }

  onGarmentTypeChange() {
    const value = this.form.get('garment_type').value;
    const option = this.decorationOptions['garment_type'].find(x => parseInt(x.id) === parseInt(value));
    if (option && option.data.value === 'OTHER') {
      this.isGarmentTypeOther = true;
      this.form.get('garment_type_other').setValidators([Validators.required]);
    } else {
      this.isGarmentTypeOther = false;
      this.form.get('garment_type_other').clearValidators();
    }
    this.form.get('garment_type_other').updateValueAndValidity();
  }

  onGarmentContentChange() {
    const value = this.form.get('garment_content').value;
    const option = this.decorationOptions['garment_content'].find(x => parseInt(x.id) === parseInt(value));
    if (option && option.data.value === 'OTHER') {
      this.isGarmentContentOther = true;
      this.form.get('garment_content_other').setValidators([Validators.required]);
    } else {
      this.isGarmentContentOther = false;
      this.form.get('garment_content_other').clearValidators();
    }
    this.form.get('garment_content_other').updateValueAndValidity();
  }

  onGarmentColorChange() {
    const value = this.form.get('garment_color').value;
    const option = this.decorationOptions['garment_color'].find(x => parseInt(x.id) === parseInt(value));
    if (option && option.data.value === 'OTHER') {
      this.isGarmentColorOther = true;
      this.form.get('garment_color_other').setValidators([Validators.required]);
    } else {
      this.isGarmentColorOther = false;
      this.form.get('garment_color_other').clearValidators();
    }
    this.form.get('garment_color_other').updateValueAndValidity();
  }

  onFabricTypeChange(data?) {
    const fabricTypeId = this.form.get('fabric_type').value;
    const fabricType = this.decorationOptions['fabric_type'].find(x => parseInt(x.id) === parseInt(fabricTypeId));
    this.fabricColorArray = this.decorationOptions['fabric_color'].filter(x => x.data.fabric_type === fabricType.data.value);
    if (!data) {
      this.form.get('fabric_color').setValue('');
    } else {
      this.form.get('fabric_color').setValue(data?.fabric_color?.id);
    }
  }

  groupByFn = (item) => item.data.group;

  onColorChange(value) {
    const prevData = this.logoThreadDetails ? this.logoThreadDetails : [];
    this.logoThreadDetails = [];
    const threadDetailsArray = this.form.get([this.threadDetailsKey]) as FormArray;
    threadDetailsArray.controls = [];
    if (value > 0) {
      for (let i = 0; i < value; i++) {
        const index = i;
        if (!prevData[index]) {
          let threadTypeId = '';
          const defaultThread = this.threadTypeArray.find(x => x.id === this.defaultThreadType);
          if (defaultThread) {
            threadTypeId = defaultThread.id;
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
          } else {
            threadTypeId = this.threadTypeArray[0];
            const threadTypeName = this.threadTypeArray[0].data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
          }
          const tmp = {
            color_type: {
              id: threadTypeId
            },
            color: {
              id: this.threadColorArray[index][0]['id']
            },
            color_description: ''
          };
          this.logoThreadDetails.push(tmp);
          const threadDetailsForm = this.initThreadForm(tmp);
          threadDetailsArray.push(threadDetailsForm);
          if (this.threadColorArray[index] && this.threadColorArray[index][0]) {
            const selectedColor = this.threadColorArray[index][0];
            this.setThreadColor(selectedColor, index);
          }
        } else {
          if (prevData[index]['color_type']) {
            const threadType = this.threadTypeArray.find(x => parseInt(x.id) === parseInt(prevData[index]['color_type'].id));
            const threadTypeName = threadType.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
            this.logoThreadDetails.push(prevData[index]);
            const threadDetailsFormData = this.initThreadForm(prevData[index]);
            threadDetailsArray.push(threadDetailsFormData);
          }

        }
      }
    }
  }

  get threadDetailsFormData() { return this.form.get([this.threadDetailsKey]) as FormArray; }

  initThreadForm(data?) {
    if (data) {
      return this.fb.group({
        'color_description': [data.color_description],
        'color_type': this.fb.group({
          'id': [data.color_type.id, Validators.required],
        }),
        'color': this.fb.group({
          'id': [data.color.id, Validators.required],
        }),
        'stitch_count': [{ value: data.stitch_count ? data.stitch_count : 0, disabled: this.threadDetailsKey === 'color_details' ? true : false }],
      });
    } else {
      return this.fb.group({
        'color_description': [''],
        'color_type': this.fb.group({
          'id': [''],
        }),
        'color': this.fb.group({
          'id': [''],
        }),
        'stitch_count': [{ value: '', disabled: this.threadDetailsKey === 'color_details' ? true : false }],
      });
    }
  }

  setThreadColor(selectedColor, index) {
    if (isEmpty(selectedColor)) {
      this.form.get([this.threadDetailsKey, index, 'color', 'id']).setValue('');
      this.selectedThreadColor[index] = '';
    } else {
      this.form.get([this.threadDetailsKey, index, 'color', 'id']).setValue(selectedColor.id);
      this.selectedThreadColor[index] = selectedColor.data.color_name;
    }
  }

  getThreadColors(index) {
    const threadTypeId = this.threadDetailsFormData.get([index, 'color_type', 'id']).value;
    const defaultThread = this.threadTypeArray.find(x => parseInt(x.id) === parseInt(threadTypeId));
    const threadTypeName = defaultThread.data.value;
    const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
    return allowedColors;
  }

  onThreadColorSelect(selectedColor, index) {
    if (selectedColor) {
      if (this.logoThreadDetails[index]['color'] && this.logoThreadDetails[index]['color']['id']) {
        this.logoThreadDetails[index]['color'].id = selectedColor.id;
      } else {
        this.logoThreadDetails[index]['color'] = {
          id: selectedColor.id
        };
      }
      this.setThreadColor(selectedColor, index);
    }
  }

  threadChange(event, key, index) {
    if (key === 'color_description') {
      this.logoThreadDetails[index][key] = event.target.value;
    }
    if (key === 'color_type') {
      const allowedColors = this.getThreadColors(index);
      this.logoThreadDetails[index]['color_type']['id'] = event.target.value;
      if (this.logoThreadDetails[index]['color'] && this.logoThreadDetails[index]['color']['id']) {
        this.logoThreadDetails[index]['color']['id'] = allowedColors[0].id ? allowedColors[0].id : '';
      } else {
        this.logoThreadDetails[index]['color'] = {
          id: allowedColors[0].id ? allowedColors[0].id : '',
        };
      }

      if (allowedColors) {
        this.threadColorArray[index] = allowedColors;
      } else {
        this.threadColorArray[index] = [];
      }
      if (this.threadColorArray[index] && this.threadColorArray[index][0]) {
        const selectedColor = this.threadColorArray[index][0];
        this.setThreadColor(selectedColor, index);
      } else {
        this.setThreadColor('', index);
      }
    }
  }

  onLaundryChange(value) {
    this.form.get('laundry_type').setValue(value);
  }

  addLineText() {
    const linesFormArray = this.form.get('lines') as FormArray;
    const lineCtrl = new FormControl('', Validators.required);
    linesFormArray.push(lineCtrl);
  }

  onClickDownloadTemplate() {
    this.downloadTemplate.emit(true);
  }

  templateFileChange($event) {
    const files = $event.target.files || $event.srcElement.files;
    if (files && files[0]) {
      this.uploadInsertTemplate.emit(files[0]);
    }
  }
}

import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-credit-card-image',
  template: '<img class="card-img" [src]="cardImg[selectedCard]">',
  styles: ['.card-img { width: auto !important; vertical-align: middle; }']
})
export class CreditCardImageComponent implements OnChanges {
  @Input() selectedCard: string;
  cardImg = {
    'visa': 'assets/images/others/visa-card.png',
    'mastercard': 'assets/images/others/master-card.png',
    'american': 'assets/images/others/american.png',
    'discover': 'assets/images/others/discover.png',
    'jcb': 'assets/images/others/jcb.png',
    'other': 'assets/images/others/other.png'
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCard && this.selectedCard) {
      const split = this.selectedCard.split(' ');
      this.selectedCard = split[0].toLowerCase();
      // eslint-disable-next-line no-prototype-builtins
      if (!this.cardImg.hasOwnProperty(this.selectedCard)) {
        this.selectedCard = 'other';
      }
    }
  }
}

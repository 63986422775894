import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';

import { SalesOneProCommonModule } from '@salesonepro/common.module';
import { AuthInterceptor } from './interceptor';


import {
  ThreadDetailsFormInjectorComponent,
  DecorationTypeFormComponent,
  DecorationTypeListingComponent,
  FlatEmbroideryFormComponent,
  SublimationFormComponent,
  TextEmbroideryFormComponent,
  TextEmbroideryAdditionalFormComponent,
  TextEmbroideryListingComponent,
  TextInsertFormComponent,
  ScreenPrintingAdditionalFormComponent,
  ScreenPrintingFormComponent,

  SharedArtworkAdditionalFormComponent,
  SharedArtworkFormComponent,
  SharedArtworkListingComponent,
  InsertListDetailsComponent,
  SharedLineOnlyFormComponent

} from './decoration-type';
import { DstPreviewComponent } from './dst-preview/dst-preview.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { AddressBlockComponent } from './address-block/address-block.component';
import { AddressChangeBlockComponent } from './address-change-block/address-change-block.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { ArtworkPreviewComponent } from './artwork-preview/artwork-preview.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { CreditCardImageComponent } from './credit-card-img.component';
@NgModule({
  declarations: [
    CreditCardImageComponent,
    ThreadDetailsFormInjectorComponent,
    DecorationTypeFormComponent,
    DecorationTypeListingComponent,
    FlatEmbroideryFormComponent,
    SublimationFormComponent,
    TextEmbroideryFormComponent,
    TextEmbroideryAdditionalFormComponent,
    TextEmbroideryListingComponent,
    TextInsertFormComponent,
    ScreenPrintingAdditionalFormComponent,
    ScreenPrintingFormComponent,
    InsertListDetailsComponent,
    SharedLineOnlyFormComponent,

    SharedArtworkAdditionalFormComponent,
    SharedArtworkFormComponent,
    SharedArtworkListingComponent,
    DstPreviewComponent,
    AddressFormComponent,
    AddressBlockComponent,
    ArtworkPreviewComponent,
    SignaturePadComponent,
    AddressChangeBlockComponent,
    AddressAutocompleteComponent
  ],
  imports: [
    SalesOneProCommonModule,
    CommonModule,
    NgSelectModule
  ],
  exports: [
    NgSelectModule,
    SalesOneProCommonModule,

    CreditCardImageComponent,
    ThreadDetailsFormInjectorComponent,
    DecorationTypeFormComponent,
    DecorationTypeListingComponent,
    FlatEmbroideryFormComponent,
    SublimationFormComponent,
    TextEmbroideryFormComponent,
    TextEmbroideryAdditionalFormComponent,
    TextEmbroideryListingComponent,
    ScreenPrintingAdditionalFormComponent,
    ScreenPrintingFormComponent,
    InsertListDetailsComponent,
    SharedLineOnlyFormComponent,

    SharedArtworkAdditionalFormComponent,
    SharedArtworkFormComponent,
    SharedArtworkListingComponent,
    DstPreviewComponent,
    AddressFormComponent,
    AddressBlockComponent,
    ArtworkPreviewComponent,
    SignaturePadComponent,
    AddressChangeBlockComponent,
    AddressAutocompleteComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }

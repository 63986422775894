<ng-container *ngIf="!loadingDecorationOptions">
  <ng-container formGroupName="data">
    <ng-container [formGroupName]="decorationMethod">
      <div class="row" *ngIf="decorationSchemaKeys.includes('emblem_line1')">
        <div class="col-md-3 my-2">
          <label class="font-xs">Line 1 <small class="text-danger">*</small></label>
          <input type="text" class="form-control" formControlName="emblem_line1">
          <app-validation-errors fieldName="Line 1" [submitted]="invalidForm" [control]="form.get('emblem_line1')">
          </app-validation-errors>
        </div>
      </div>
      <ng-container *ngFor="let l of [].constructor(noOfLines); let i = index">
        <div class="row" *ngIf="i > 0 && decorationSchemaKeys.includes('emblem_line'+(i + 1))">
          <div class="col-md-3 my-2">
            <label class="font-xs">Line {{i + 1}}</label>
            <input type="text" class="form-control" formControlName="emblem_line{{i + 1}}">
            <app-validation-errors fieldName="Line {{i + 1}}" [submitted]="invalidForm" [control]="form.get('emblem_line{{i + 1}}')">
            </app-validation-errors>
          </div>
        </div>
      </ng-container>

    </ng-container>
  </ng-container>
</ng-container>
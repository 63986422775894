import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';


import { ApiService, StorageService, ToasterService } from '@salesonepro/services';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private apiService: ApiService,
    private storage: StorageService,
    private toastr: ToasterService,
  ) { }

  getCustomerInfo(params) {
    return this.apiService.get('/contact/customers', params).pipe(
      map(
        data => { return data; }
      ));
  }

  getCustomerThreads() {
    if (!this.storage.retrieve('customerThreads', 'session') && this.storage.retrieve('default_customer')) {
      const url = '/threads';
      this.apiService.get(url).subscribe(response => {
        this.storage.store('customerThreads', response, 'session');
      });
    }
  }

  /** Get customer lettering styles */
  getCustomerLetteringStyles() {
    if (!this.storage.retrieve('customerLetteringStyles', 'session') && this.storage.retrieve('default_customer')) {
      const url = '/lettering';
      this.apiService.get(url).subscribe(response => {
        this.storage.store('customerLetteringStyles', response, 'session');
      });
    }
  }

  /** Get user acl*/
  getUserAcl() {
    if (!this.storage.retrieve('acl', 'session')) {
      this.apiService.get('/users/acl')
        .subscribe(response => {
          this.storage.store('acl', response, 'session');
        });
    }
  }

  // forgot password
  forgotPassword(data) {
    return this.apiService
      .post('/account/forgot_password', data).pipe(
        tap(
          _ => {
            this.toastr.success(
              ' Password reset link has been sent to your email '
            );
          },
          _ =>
            this.toastr.success(
              'Your Email is not registered with us'
            )
        )
      );
  }
}

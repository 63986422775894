import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

import { AddressAutocompleteService } from './address-autocomplete.service';


@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss']
})
export class AddressAutocompleteComponent implements OnChanges {
  @Input() selectedEntry: string;
  @Input() filters: any;
  @Input() placeholder = 'Select Address';
  @Input() reload: boolean;
  @Input() newAddress: any;
  @Input() invalidForm: boolean;
  @Input() inputFormControl: FormControl = new FormControl();
  @Output() itemSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('ngSelectElement') ngSelectElement!: NgSelectComponent;

  listData: Array<any> = [];
  resultSet = {
    count: 0,
    next: '',
    previous: '',
    results: []
  };

  loadingListData: boolean;

  searchKey = new Subject<string>();

  constructor(
    private addressAutocompleteService: AddressAutocompleteService,
  ) {
    this.searchKey
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(term => {
        const params = new HttpParams().append('search', term);
        this.listData = [];
        this.getlistData(params);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.newAddress && this.newAddress) {
      this.listData = [];
      const params = new HttpParams().append('ordering', '-datetime_created');
      this.getlistData(params);
    }

    if (changes.inputFormControl && this.inputFormControl && this.inputFormControl.value) {
      const params = new HttpParams().append('ordering', '-datetime_created').append('id', this.inputFormControl.value);
      this.getlistData(params);
    } else if (changes.selectedEntry && this.selectedEntry) {
      const params = new HttpParams().append('ordering', '-datetime_created').append('id', this.selectedEntry);
      this.getlistData(params);
    }

    if (changes.reload && this.reload) {
      this.listData.length = 0;
      this.selectedEntry = null;
      this.onOpenSelect();
    }
    if (changes.invalidForm && this.invalidForm && this.ngSelectElement.element.classList.contains('ng-invalid')) {
      this.ngSelectElement.focus();
    }
  }

  onOpenSelect() {
    if (!this.selectedEntry) {
      const params = new HttpParams().append('ordering', '-datetime_created');
      this.getlistData(params);
    }
  }

  getlistData(params: HttpParams) {
    if (this.filters) {
      for (const key in this.filters) {
        if (this.filters[key] && this.filters[key] !== '') {
          params = params.delete(key);
          if (key !== 'not_id') {
            params = params.append(key, this.filters[key]);
          } else {
            params = params.append('id!', this.filters[key]);
          }
        }
      }
    }
    this.loadingListData = true;
    this.addressAutocompleteService.getAddressList(params).subscribe(response => {
      this.loadingListData = false;
      this.resultSet = response;
      if (response.count > 0) {
        const allItems = [...this.listData, ...response.results];
        const uniqueItemsMap = new Map(allItems.map(item => [item.id, item]));
        this.listData = Array.from(uniqueItemsMap.values());

        if (this.selectedEntry && this.inputFormControl.value === '') {
          this.inputFormControl.setValue(this.selectedEntry);
        }
      }
      if (this.newAddress && this.newAddress.id) {
        const findNewAddress = this.listData.find(x => x.id === this.newAddress.id);
        if (!findNewAddress) {
          this.listData.unshift(this.newAddress);
          this.inputFormControl.setValue(this.newAddress.id);
        }
      }

      const findAddNewAddress = this.listData.find(x => x.id === 'new');
      if (!findAddNewAddress) {
        this.listData.unshift({ id: 'new', name: '+ Add New Address' });
      }
    }, (error: any) => {
      this.loadingListData = false;
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.address_1?.toLowerCase().includes(term) ||
      item.name?.toLowerCase().includes(term) ||
      item.state?.toLowerCase().includes(term) ||
      item.company_name?.toLowerCase().includes(term) ||
      item.city?.toLowerCase().includes(term) ||
      item.zip?.toLowerCase().includes(term)
    );
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    const attributeOptionCount = this.listData.length;
    const resultSet = this.resultSet;
    if (this.loadingListData || resultSet.count <= attributeOptionCount) {
      return;
    }
    if (end + 10 >= attributeOptionCount) {
      this.fetchMore();
    }
  }

  private fetchMore() {
    const resultSet = this.resultSet;
    if (resultSet.next && resultSet.next !== '') {
      const tmp = resultSet.next.split('?');
      if (tmp[1]) {
        const urlParams = tmp[1].split('&');
        let params = new HttpParams();
        urlParams.forEach(element => {
          const data = element.split('=');
          if (data[0] === 'offset') {
            params = params.delete(data[0]);
            params = params.append(data[0], data[1]);
          }
          if (data[0] === 'ordering') {
            params = params.delete(data[0]);
            params = params.append(data[0], data[1]);
          }
        });
        this.getlistData(params);
      }
    }
  }

  onFilterClear() {
    const params = new HttpParams().append('ordering', '-datetime_created');
    this.listData = [];
    this.getlistData(params);
  }

  onFilterSearch({ term }) {
    this.searchKey.next(term);
  }

  onSelect(data) {
    this.itemSelect.emit(data);
  }
}

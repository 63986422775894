import { Component, Inject, Input, OnInit, OnDestroy, Output, EventEmitter, Optional } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, ControlContainer, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-recaptcha-form',
  templateUrl: './recaptcha-form.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
})
export class RecaptchaFormComponent implements OnInit, OnDestroy {
  @Input() groupName: string;
  @Input() formInvalid: boolean;
  @Input() showCaptcha:boolean;
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup;
  formGroup: FormGroup;
  siteKey: string;
  unsubscriber$ = new Subject<void>();

  constructor(
    @Optional() private parent: FormGroupDirective,
    private fb: FormBuilder,
    private authService: AuthService,
    @Inject('environment') environment
  ) {
    this.siteKey = environment.siteKey;
  }

  ngOnInit() {
    if (this.parent && this.parent.form) {
      this.form = this.parent.form;
    } else {
      this.form = this.fb.group({});
    }
    if (this.groupName) {
      this.formGroup = this.form.get(this.groupName) as  FormGroup;
    } else {
      this.formGroup = this.form;
    }
    this.formGroup.addControl('recaptcha', new FormControl('', Validators.required));
    if (!this.showCaptcha) {
      this.authService.isCaptchaRequired
      .pipe(
        delay(0),
        takeUntil(this.unsubscriber$)
      )
      .subscribe(response => {
        if (response) {
          this.showCaptcha = true;
          this.formGroup.get('recaptcha').enable();
        } else {
          this.showCaptcha = false;
          this.formGroup.get('recaptcha').disable();
        }
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
  }

  handleSuccess(token) {
    this.formGroup.get('recaptcha').setValue(token);
    const data = {
      token: token
    };
    this.authService.validateCaptcha(data).subscribe(response => {
      this.saved.emit(true);
    });
  }
}

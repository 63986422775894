import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { ConfirmationOverlayRef } from './confirmation-dialog-ref';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  confirm<R = any, T = any>(
    title: string,
    message: string,
    btnOkText: string,
    btnCancelText?: string
  ): ConfirmationOverlayRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'd-block', 'confirmation-dialog'],
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
    const inputData = {
      title,
      message,
      btnOkText,
      btnCancelText
    };
    const overlayRef = this.overlay.create(configs);
    const myOverlayRef = new ConfirmationOverlayRef<R>(overlayRef, inputData);

    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(new ComponentPortal(ConfirmationDialogComponent, null, injector));

    return myOverlayRef;
  }

  createInjector(ref: ConfirmationOverlayRef, inj: Injector) {
    return Injector.create({
      parent: inj,
      providers: [
        { provide: ConfirmationOverlayRef, useValue: ref }
      ]
    });
  }
}
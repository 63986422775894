import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccountService } from '@core/account/account.service';

// third-party
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  breadcrumbs:any;
  showBreadcrumb: boolean = true;
  private routerEventsSubscription: Subscription;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService
  ) {
    this.accountService.showBreadcrumb$.subscribe(state => {
      this.showBreadcrumb = state;
    });

    if (this.showBreadcrumb) {
      this.routerEventsSubscription = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.updateBreadcrumbs();
      });
    }
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  updateBreadcrumbs() {
    this.breadcrumbs = [];
    let currentRoute = this.route.root;
    let url = '';
    while (currentRoute) {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;

      childrenRoutes.forEach(route => {
        if (route.outlet === 'primary') {
          const routeSnapshot = route.snapshot;
          const currentUrl = routeSnapshot.url.map(segment => segment.path).join('/');
          if (currentUrl) {
            url += `/${currentUrl}`;
          }
          const normalizedUrl = url.replace(/\/+$/, '');
          const breadcrumb = route.snapshot.data.breadcrumb;
          const status = route.snapshot.data.status !== undefined ? route.snapshot.data.status : true;
          if (breadcrumb && !this.breadcrumbs.some(bc => bc.label === breadcrumb && bc.url === normalizedUrl)) {
            this.breadcrumbs.push({
              label: breadcrumb,
              status: status,
              url: normalizedUrl
            });
          }
          currentRoute = route;
        }
      });
    }
  }

}

import { HttpParams } from '@angular/common/http';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup, FormGroupDirective, ControlContainer } from '@angular/forms';
import { debounceTime, filter, tap } from 'rxjs/operators';

// services
import { SharedService, StorageService } from '@salesonepro/services';

// others
import { CustomValidator } from '@salesonepro/custom-validator';
import { phoneNumberExtMask } from '@salesonepro/utils';

@Component({
  selector: 'app-shared-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
})
export class AddressFormComponent implements OnInit {

  @Input() invalidFormData: boolean;
  @Input() groupName: string | undefined;
  @Input() isLoading: boolean;
  form!: FormGroup;
  addressForm!: FormGroup;

  countryList = [];
  stateList = [];
  mask = phoneNumberExtMask;
  errors: any;
  defaultCountry = 'US';

  constructor(
    private parent: FormGroupDirective,
    private storage: StorageService,
    private ngZone: NgZone,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.getCountryState();
    this.form = this.parent.form;
    if (this.groupName) {
      this.addressForm = this.form.get(this.groupName) as FormGroup;
    } else {
      this.addressForm = this.form;
    }

    this.addressForm.addControl('id', new FormControl(''));
    this.addressForm.addControl('name', new FormControl('', [Validators.required]));
    this.addressForm.addControl('email', new FormControl('', [Validators.required, CustomValidator.email]));
    this.addressForm.addControl('company_name', new FormControl(''));
    this.addressForm.addControl('address_1', new FormControl('', [Validators.required]));
    this.addressForm.addControl('address_2', new FormControl(''));
    this.addressForm.addControl('city', new FormControl('', [Validators.required]));
    this.addressForm.addControl('state', new FormControl(''));
    this.addressForm.addControl('state_text', new FormControl(''));
    this.addressForm.addControl('country', new FormControl(this.defaultCountry, Validators.required));
    this.addressForm.addControl('zip', new FormControl('', [Validators.required]));
    this.addressForm.addControl('phone', new FormControl('', [Validators.required]));
    const currentUser = this.storage.retrieve('user');
    if (currentUser) {
      if (!this.addressForm.value.email) {
        this.addressForm.get('email').setValue(currentUser.email);
      }
      if (!this.addressForm.value.name) {
        this.addressForm.get('name').setValue(currentUser.name);
      }
    }
    this.initValueChanges();
  }

  getCountryState() {
    const countryStateList = this.storage.retrieve('country-state-list');
    this.countryList = countryStateList['countries'].filter((obj => [this.defaultCountry].includes(obj.code)));
    const country = this.countryList.find(obj => obj.code === this.defaultCountry);
    if (country) {
      this.stateList = country['states'] ? country['states'] : [];
    }
  }

  fillInAddress(addressData) {
    if (Object.keys(addressData).length) {
      this.ngZone.run(() => {
        this.addressForm.controls['address_1'].setValue(addressData.address_1);
        this.addressForm.controls['address_2'].setValue(addressData.address_2);
        this.addressForm.controls['city'].setValue(addressData.city);
        this.addressForm.controls['state'].setValue(addressData.state);
        this.addressForm.controls['zip'].setValue(addressData.zip);
        this.addressForm.controls['country'].setValue(addressData.country);
        this.onChangeCountry(addressData.country);
      });
    }
  }

  onChangeCountry(item) {
    if (item) {
      this.stateList = this.countryList.find(obj => obj.code === item).states;
    } else {
      this.stateList = [];
    }
    const stateControl = this.addressForm.controls['state'];
    const stateTextCtrl = this.addressForm.controls['state_text'];
    if (this.stateList.length === 0 && this.addressForm.controls['country'].value !== 'US') {
      stateControl.clearValidators();
      stateControl.updateValueAndValidity();
      stateTextCtrl.setValidators([Validators.required]);
      stateTextCtrl.updateValueAndValidity();
      stateControl.reset();
    } else {
      stateControl.setValidators([Validators.required]);
      stateControl.updateValueAndValidity();
      stateTextCtrl.clearValidators();
      stateTextCtrl.updateValueAndValidity();
      stateTextCtrl.reset();
    }
  }

  onChangeZipCode(zip) {
    if (this.addressForm.controls['country'].value === 'US' && zip.trim().length > 3) {
      let params = new HttpParams();
      params = params.append('zip', zip.trim());
      this.sharedService.getZipcodeDetails(params).subscribe((response) => {
        if (response && response.state) {
          this.addressForm.get('state').setValue(response.state);
        }
      });
    }
  }

  initValueChanges() {
    this.addressForm.get('zip')?.valueChanges.pipe(
      debounceTime(300),
      filter(v => v && v.length > 4),
      tap(v => {
        this.sharedService.validateAddress(this.addressForm.value)
          .subscribe(res => {
            this.addressForm.get('zip')?.updateValueAndValidity({ emitEvent: false });
          }, error => {
            if (error && Object.keys(error).find(k => k === 'zip')) {
              this.addressForm.get('zip')?.setErrors({ stateDoesntMatch: true });
            }
          });
      })
    ).subscribe();

    // this.addressForm.get('state')?.valueChanges.pipe(debounceTime(300))
    //   .subscribe(v => {
    //     if (v) {
    //       this.addressForm.get('zip')?.setValidators([Validators.required, Validators.minLength(5), onlyBlankSpaceValidator]);
    //       this.addressForm.get('zip')?.markAsTouched();
    //       this.addressForm.get('zip')?.markAsDirty();
    //       this.addressForm.get('zip')?.updateValueAndValidity();
    //     }
    //   });
  }

  get name() { return this.addressForm.get('name'); }
  get email() { return this.addressForm.get('email'); }
  get address_1() { return this.addressForm.get('address_1'); }
  get address_2() { return this.addressForm.get('address_2'); }
  get city() { return this.addressForm.get('city'); }
  get state() { return this.addressForm.get('state'); }
  get state_text() { return this.addressForm.get('state_text'); }
  get country() { return this.addressForm.get('country'); }
  get zip() { return this.addressForm.get('zip'); }
  get phone() { return this.addressForm.get('phone'); }

}
